import React, { useState } from 'react';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption
} from 'reactstrap';
// import LandOwnerApplication from '../forms/LandOwnerApplication';

const items = [
  {
    src: '/images/home/GEO_7258-scaled.jpg',
    altText: 'CULTIVATING SUSTAINABILITY',
    header: 'CULTIVATING SUSTAINABILITY',
    caption: "Think big. Think sustainable."
  },
  {
    src: '/images/home/GEO_7628-scaled.jpg',
    altText: 'Promoting innovation',
    header: 'Promoting innovation',
    caption: 'A B2B model enhancing raw material supply and quality control.'
  },
  {
    src: '/images/home/GEO_7545-scaled.jpg',
    altText: 'Inspiring professionalism',
    header: 'Inspiring professionalism',
    caption: 'An experienced team emphasizing professional performance and credibility.'
  },
  {
    src: '/images/home/sec3img.jpg',
    altText: 'Valuating Sustainability',
    header: 'Valuating Sustainability',
    caption: 'An eco-friendly model maintaining sustainable societies and environments.'
  }

];

const ImgSlider = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  }

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  }

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  }

  const slides = items.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}
      >
        <img alt="" src={item.src} />
        <CarouselCaption captionText={item.caption} captionHeader={item.header.toUpperCase()} />
      </CarouselItem>
    );
  });

  return (
    <div id="imgSliderMainContainer">
      {/* <div class="floatInTheMiddle" style={{height:"50px", width:"300px", color: "#fff", textAlign: "center"}}>
        <h1>CULTIVATING</h1>
        <h1> SUSTAINABILITY</h1>
        <h5>Think big. Think sustainable.</h5>
        </div> */}
      <div style={{ position: "relative" }}>
        <Carousel
          activeIndex={activeIndex}
          next={next}
          previous={previous}
        >
          <CarouselIndicators items={items} activeIndex={activeIndex} style={{ filter: "brightness(50%)" }} onClickHandler={goToIndex} />
          {slides}
          <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
          <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
          <div style={{ position: "absolute" }}></div>
        </Carousel>
        {/* <div style={{position:"absolute"}}><LandOwnerApplication/></div> */}
        <div id="SliderSocialMedia" style={{ paddingTop: "30px", marginRight:"20px"}}>
          {/* <div><img alt="" src='/images/footer/Component112.svg' height="22px" /></div> */}
          {/* <div style={{paddingLeft:"1em"}}><img alt="" src='/images/footer/Component113.svg' height="22px" /></div> */}
          {/* <div style={{paddingLeft:"1em"}}><img alt="" src='/images/footer/Component114.svg' height="22px" /></div> */}
          <div><a href='https://www.youtube.com/channel/UChOJuC8BqU1m7r82vsEDaow' target="_blank" rel="noopener noreferrer"><img alt="" src='/images/footer/Component115.svg' height="22px" /></a></div>
          <div style={{paddingLeft:"1em"}}><a href='https://www.linkedin.com/company/agricultura-plus' target="_blank" rel="noopener noreferrer"><img alt="" src='/images/footer/Component116.svg' height="22px" /></a></div>
        </div>
      </div>
    </div>
  );
}
export default ImgSlider;