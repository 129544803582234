import React from 'react';
import {
  Button,
  FormGroup,
  // Label,
  Input,
  Alert,
} from 'reactstrap';
import { cloneDeep, remove, includes } from "lodash"
import Footer from './Footer';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
      UserName: "",
      Password: ""
    };
  }

  login = () => {
    if (this.state.UserName.toLowerCase() === "admin") {
      localStorage.setItem('userType', "admin");
      this.props.setIsAuthenticated("A")
      this.props.history.push("/SystemManagement");
    } else if (this.state.UserName.toLowerCase() === "manager") {
      localStorage.setItem('userType', "manager");
      this.props.setIsAuthenticated("M")
      this.props.history.push("/ManagerForms");
    } else if (this.state.UserName.toLowerCase() === "user") {
      localStorage.setItem('userType', "user");
      this.props.setIsAuthenticated("U")
      this.props.history.push("/Dashborad");
    }
  }

  handleChangePath = (url) => {
    this.props.history.push(url);
  }

  activateSuccessMsg = (message) => {
    this.setState({ message: message })

    setTimeout(() => {
      this.setState({ message: "" })
    }, 8000)
  }

  handleTextChange = val => event => {
    let tempMandatory = cloneDeep(this.state.tempMandatory)
    let { mandatoryFields } = this.state

    if (includes(mandatoryFields, val)) {
      tempMandatory = remove(tempMandatory, element => {
        return element !== val
      })
    }
    this.setState({ tempMandatory, [val]: event.target.value, showErrorMessage: '' })
  }

  render() {
    let { message, UserName, Password } = this.state
    return (
      <div id="LoginMainContainer" className="container" >
        {message &&
          <Alert style={{ width: "calc(100% - 47px)", position: "absolute", zIndex: "100" }} color="success">
            {message}
          </Alert>
        }
        <div id="LoginContainer" style={{ height: "100%" }} >
          <div className="row" style={{ height: "100%" }} >
            <div className="col-sm-12 col-md-5" style={{ textAlign: "center" }}>
              <FormGroup>
                <div className="row">
                  <div className="col-12">
                    <div className="row"  >
                      <div className="col-12" style={{ fontSize: "60px", fontWeight: "bold", color: "#22b05b" }}>
                        Manage.
                      </div>
                      <div className="col-12" style={{ fontSize: "60px", fontWeight: "bold", color: "#22b05b" }}>
                        Monitor.
                      </div>
                      <div className="col-12" style={{ fontSize: "60px", fontWeight: "bold", color: "#22b05b" }}>
                        Review.
                      </div>
                    </div>
                  </div>
                  <div className="col-12 mt-3">
                    {/* <Label >UserName</Label> */}
                    <Input type="text" name="UserName" id="UserName"
                      value={UserName ? UserName : ""}
                      placeholder="UserName"
                      onChange={this.handleTextChange("UserName")} />
                  </div>
                  <div className="col-12 mt-3">
                    {/* <Label >Password</Label> */}
                    <Input type="password" name="Password" id="Password"
                      value={Password ? Password : ""}
                      placeholder="Password"
                      onChange={this.handleTextChange("Password")} />
                  </div>
                </div>
              </FormGroup>
              <Button className="agri-background mb-4" onClick={this.login}>Login</Button>
            </div>
            <div className="col-sm-12 col-md-7">
              <img alt="" src='/images/home/OurImpact.jpg' style={{ height: "100%", width: "100%", objectFit: "cover" }} />
            </div>
          </div>
        </div>
        <div><Footer /></div>
      </div>
    )
  }
}
export default Login;

