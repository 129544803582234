import React from 'react';
// import axios from 'axios'
import {
    Button,
    Form,
    FormGroup,
    Label,
    Input,
    Alert,
    Table,
} from 'reactstrap';
import { map, cloneDeep, isEmpty, remove, includes } from "lodash"

class NewProject extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ProjectName: '',
            Client: '',
            Land: '',
            ProjectDuration: '',
            Notes: '',

            ZaatarCheckbox: false,
            SummacCheckbox: false,
            BasilCheckbox: false,
            AvocadoCheckbox: false,
            EstablishmentArray: [],

            Establishment: "",
            StartDate: "",
            EndDate: "",
            startDateIsGreaterThanEndDate: false,
            mandatoryFields: [
                'ProjectName',
                'Client',
                'Land',
                'ProjectDuration',
            ],

            tempMandatory: [],
            showErrorMessage: ''
        };
    }

    handleTextChange = val => event => {
        let tempMandatory = cloneDeep(this.state.tempMandatory)
        let { mandatoryFields } = this.state
        if (includes(mandatoryFields, val) ||
            val === 'Establishment' ||
            val === 'StartDate' ||
            val === 'EndDate') {
            tempMandatory = remove(tempMandatory, element => {
                return element !== val
            })
        }

        if (val === 'StartDate' || val === 'EndDate') {
            this.setState({ startDateIsGreaterThanEndDate: false })
        }

        this.setState({ tempMandatory, [val]: event.target.value, showErrorMessage: '' })
    }

    handleRadioChange = (trueVal, falseVal) => event => {
        let tempMandatory = cloneDeep(this.state.tempMandatory)
        let obj = {}

        if (trueVal === "ZaatarCheckbox" || trueVal === "SummacCheckbox" || trueVal === "BasilCheckbox" || trueVal === "AvocadoCheckbox") {
            if (includes(tempMandatory, "TypeOfPlants")) {
                tempMandatory = remove(tempMandatory, element => {
                    return element !== "TypeOfPlants"
                })
            }
        }

        map(falseVal, eachElem => {
            return obj = { ...obj, [eachElem]: false }
        })

        this.setState({ ...this.state, ...obj, [trueVal]: true, tempMandatory })
    }

    handleSubmit = () => {
        this.validateForm()
    }

    validateForm = () => {
        let tempMandatory = cloneDeep(this.state.tempMandatory)

        map(this.state.mandatoryFields, eachElem => {
            if (isEmpty(this.state[eachElem]) && !includes(tempMandatory, eachElem))
                tempMandatory.push(eachElem)
        })

        if (!this.state.ZaatarCheckbox &&
            !this.state.SummacCheckbox &&
            !this.state.BasilCheckbox &&
            !this.state.AvocadoCheckbox)
            tempMandatory.push("TypeOfPlants")

            if (this.state.Establishment )
                tempMandatory.push("Establishment")
                if (this.state.StartDate )
                tempMandatory.push("StartDate")
                if (this.state.EndDate )
                tempMandatory.push("EndDate")
        this.setState({ tempMandatory, showErrorMessage: !isEmpty(tempMandatory) ? 'Some fields are empty or invalid' : '' })

        setTimeout(() => {
            this.setState({ showErrorMessage: '' })
        }, 8000)
        if (!isEmpty(tempMandatory)) return false
        else return true
    }

    addNewEstablishment = () => {
        let { Establishment, StartDate, EndDate } = this.state
        let EstablishmentArray = cloneDeep(this.state.EstablishmentArray)
        let tempMandatory = cloneDeep(this.state.tempMandatory)
        if (isEmpty(Establishment || isEmpty(StartDate) || isEmpty(EndDate))) {

            if (isEmpty(Establishment) && !includes(tempMandatory, "Establishment"))
                tempMandatory.push("Establishment")
            if (isEmpty(StartDate) && !includes(tempMandatory, "StartDate"))
                tempMandatory.push("StartDate")
            if (isEmpty(EndDate) && !includes(tempMandatory, "EndDate"))
                tempMandatory.push("EndDate")
            this.setState({ tempMandatory })
            return
        } else if (StartDate > EndDate) {
            this.setState({ startDateIsGreaterThanEndDate: true })
        }
        else {
            EstablishmentArray.push({
                Establishment: Establishment,
                StartDate: StartDate,
                EndDate: EndDate,
            })
        this.clearEstablishment()
        this.setState({ EstablishmentArray})
        }
    }

     clearEstablishment = () => {
         this.setState({ Establishment:'', StartDate:'', EndDate:'', startDateIsGreaterThanEndDate: false })
     }

     deleteEstablishment =(Establishment)=>e =>{
        let EstablishmentArray = cloneDeep(this.state.EstablishmentArray)
        EstablishmentArray = remove(EstablishmentArray, eachElem=> {
            return eachElem.Establishment !== Establishment;
          });
          this.setState({EstablishmentArray})
     }

    render() {
        let {
            ProjectName,
            Client,
            Land,
            ProjectDuration,
            Notes,
            ZaatarCheckbox,
            SummacCheckbox,
            BasilCheckbox,
            AvocadoCheckbox,
            EstablishmentArray,

            Establishment,
            StartDate,
            EndDate,
            startDateIsGreaterThanEndDate,

            tempMandatory, showErrorMessage } = this.state
        return (
            <>
                {showErrorMessage &&
                    <Alert color="danger">
                        {showErrorMessage}
                    </Alert>
                }
                <h3 className="agri-color">New Project</h3>
                <Form>

                    <FormGroup>
                        <div className="row">

                            <div className="col-6">
                                <Label className={includes(tempMandatory, "ProjectName") ? "AlertRed" : ""}>Project's Name</Label>
                                <Input type="text" name="ProjectName" id="ProjectName"
                                    value={ProjectName ? ProjectName : ""}
                                    className={includes(tempMandatory, "ProjectName") ? "AlertRed" : ""}
                                    onChange={this.handleTextChange("ProjectName")} />
                            </div>

                            <div className="col-6">
                                <Label className={includes(tempMandatory, "Client") ? "AlertRed" : ""}>Client</Label>
                                <Input type="text" name="Client" id="Client"
                                    value={Client ? Client : ""}
                                    className={includes(tempMandatory, "Client") ? "AlertRed" : ""}
                                    onChange={this.handleTextChange("Client")} />
                            </div>
                            <div className="col-6">
                                <Label className={includes(tempMandatory, "Land") ? "AlertRed" : ""}>Land</Label>
                                <Input type="text" name="Land" id="Land"
                                    value={Land ? Land : ""}
                                    className={includes(tempMandatory, "Land") ? "AlertRed" : ""}
                                    onChange={this.handleTextChange("Land")} />
                            </div>
                        </div>
                    </FormGroup>
                    <FormGroup check className={includes(tempMandatory, "TypeOfPlants") ? "AlertRed" : ""}>
                        <div className="row">

                            <div className="col-3">
                                <Label >
                                    <Input type="radio" name="ZaatarCheckbox" id="ZaatarCheckbox"
                                        checked={ZaatarCheckbox}
                                        className={"AlertRed"}
                                        onChange={this.handleRadioChange("ZaatarCheckbox", ["SummacCheckbox", "BasilCheckbox", "AvocadoCheckbox"])} />Zaatar
                                </Label>
                            </div>
                            <div className="col-3">
                                <Label >
                                    <Input type="radio" name="SummacCheckbox" id="SummacCheckbox"
                                        checked={SummacCheckbox}
                                        onChange={this.handleRadioChange("SummacCheckbox", ["ZaatarCheckbox", "BasilCheckbox", "AvocadoCheckbox"])} />Summac
                                </Label>
                            </div>
                            <div className="col-3">
                                <Label >
                                    <Input type="radio" name="BasilCheckbox" id="BasilCheckbox"
                                        checked={BasilCheckbox}
                                        onChange={this.handleRadioChange("BasilCheckbox", ["ZaatarCheckbox", "SummacCheckbox", "AvocadoCheckbox"])} />Basil
                                </Label>
                            </div>
                            <div className="col-3">
                                <Label >
                                    <Input type="radio" name="AvocadoCheckbox" id="AvocadoCheckbox"
                                        checked={AvocadoCheckbox}
                                        onChange={this.handleRadioChange("AvocadoCheckbox", ["ZaatarCheckbox", "SummacCheckbox", "BasilCheckbox",])} />Avocado</Label>
                            </div>
                        </div>

                    </FormGroup>
                    <FormGroup>
                        <div className="row">
                            <div className="col-6">
                                <Label className={includes(tempMandatory, "ProjectDuration") ? "AlertRed" : ""}>ProjectDuration</Label>
                                <Input type="text" name="ProjectDuration" id="ProjectDuration"
                                    value={ProjectDuration ? ProjectDuration : ""}
                                    className={includes(tempMandatory, "ProjectDuration") ? "AlertRed" : ""}
                                    onChange={this.handleTextChange("ProjectDuration")} />
                            </div>
                        </div>
                    </FormGroup>

                    <FormGroup>
                        <div className="row">
                            <div className="col-3">
                                <Label className={includes(tempMandatory, "Establishment") ? "AlertRed" : ""}>Establishment</Label>
                                <Input type="text" name="Establishment" id="Establishment"
                                    value={Establishment ? Establishment : ""}
                                    className={includes(tempMandatory, "Establishment") ? "AlertRed" : ""}
                                    onChange={this.handleTextChange("Establishment")} />
                            </div>
                            <div className="col-3">
                                <Label className={includes(tempMandatory, "StartDate") ||startDateIsGreaterThanEndDate ? "AlertRed" : ""}>Start date</Label>
                                <Input type="date" name="StartDate" id="StartDate"
                                    value={StartDate ? StartDate : ""}
                                    className={includes(tempMandatory, "StartDate")||startDateIsGreaterThanEndDate  ? "AlertRed" : ""}
                                    onChange={this.handleTextChange("StartDate")} />
                            </div>
                            <div className="col-3">
                                <Label className={includes(tempMandatory, "EndDate") ||startDateIsGreaterThanEndDate ? "AlertRed" : ""}>End date</Label>
                                <Input type="date" name="EndDate" id="EndDate"
                                    value={EndDate ? EndDate : ""}
                                    className={includes(tempMandatory, "EndDate")||startDateIsGreaterThanEndDate  ? "AlertRed" : ""}
                                    onChange={this.handleTextChange("EndDate")} />
                                {startDateIsGreaterThanEndDate &&
                                    <Label style={{ color: 'red' }}>start date is greater than end date</Label>
                                }
                            </div>
                            <div className="col-3" style={{ marginTop: "30px" }}>
                                <Button style={{ float: 'right' }} className="secondary" onClick={this.clearEstablishment}>Clear</Button>
                                <Button style={{ float: 'right' }} className="agri-background" onClick={this.addNewEstablishment}>Submit</Button>
                            </div>
                        </div>

                        <div className="row">
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Establishment</th>
                                        <th>Start Date</th>
                                        <th>End Date</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {map(EstablishmentArray, eachElem => {
                                        return (
                                            <tr>
                                                <td>{eachElem.Establishment} </td>
                                                <td>{eachElem.StartDate}</td>
                                                <td>{eachElem.EndDate}</td>
                                                <td>
                                                    <Button color="danger" onClick={this.deleteEstablishment(eachElem.Establishment)}>-</Button>
                                                </td>
                                            </tr>
                                        )
                                    })
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </FormGroup>
                    <FormGroup>
                        <Label className={includes(tempMandatory, "Message") ? "AlertRed" : ""}>Notes</Label>
                        <Input type="textarea" name="Notes" id="Notes"
                            value={Notes ? Notes : ""}
                            onChange={this.handleTextChange("Notes")} />
                    </FormGroup>

                </Form>
                <Button style={{ float: 'right' }} className="agri-background" onClick={this.handleSubmit}>Submit</Button>
            </>
        )
    }
}

export default NewProject;

