import React from 'react';
// import axios from 'axios'
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    FormGroup,
    Label,
    Input,
    Alert,
} from 'reactstrap';
import { map, cloneDeep, isEmpty, remove, includes } from "lodash"
import axios from 'axios';

class BusinessApplication extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            CompanyName: '',
            ContactPerson: '',
            PhoneNumber: '',
            EmailAddress: '',
            RawMaterialUsage: '',
            PotentialProjectAmount: '',
            PotentialProjectAmountSelect: 'USD',
            YearlyDemandForecast: '',
            YearlyDemandForecastSelect: 'KG',
            Requirments: '',
            ZaatarCheckbox: false,
            SummacCheckbox: false,
            BasilCheckbox: false,
            MintCheckbox: false,
            PepperCheckbox: false,

            isOpen: false,

            mandatoryFields: [
                'ContactPerson',
                'PhoneNumber',
                'EmailAddress',
                'RawMaterialUsage',
                'PotentialProjectAmount',
                'YearlyDemandForecast',
            ],

            tempMandatory: [],
            showErrorMessage: ''
        };
    }

    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, false);
    }
    componentWillUnmount() {
        document.removeEventListener("keydown", this.escFunction, false);
    }

    escFunction = (event) => {
        if (event.key === "Escape"){
            this.setState({
            isOpen: false
            })
        }
    }

    handleTextChange = val => event => {
        let tempMandatory = cloneDeep(this.state.tempMandatory)
        let { mandatoryFields } = this.state

        if (includes(mandatoryFields, val)) {
            tempMandatory = remove(tempMandatory, element => {
                return element !== val
            })
        }
        this.setState({ tempMandatory, [val]: event.target.value, showErrorMessage: '' })
    }

    handleRadioChange = (trueVal, falseVal) => event => {
        let tempMandatory = cloneDeep(this.state.tempMandatory)
        let obj = {}

        if (trueVal === "ZaatarCheckbox" || trueVal === "SummacCheckbox" || trueVal === "BasilCheckbox" || trueVal === "MintCheckbox" || trueVal === "PepperCheckbox") {
            if (includes(tempMandatory, "TypeOfPlants")) {
                tempMandatory = remove(tempMandatory, element => {
                    return element !== "TypeOfPlants"
                })
            }
        }

        map(falseVal, eachElem => {
            return obj = { ...obj, [eachElem]: false }
        })

        this.setState({ ...this.state, ...obj, [trueVal]: true, tempMandatory })
    }

    handleApply = () => {
        if (this.validateForm() === true) {

            let { CompanyName,
                ContactPerson,
                PhoneNumber,
                EmailAddress,
                RawMaterialUsage,
                PotentialProjectAmount,
                PotentialProjectAmountSelect,
                YearlyDemandForecast,
                YearlyDemandForecastSelect,
                Requirments,
                ZaatarCheckbox,
                SummacCheckbox,
                BasilCheckbox,
                MintCheckbox,
                PepperCheckbox
            } = this.state
            let PlantType
            if (ZaatarCheckbox)
                PlantType = "Zaatar"
            if (SummacCheckbox)
                PlantType = "Summac"
            if (BasilCheckbox)
                PlantType = "Basil"
            if (MintCheckbox)
                PlantType = "Mint"
            if (PepperCheckbox)
                PlantType = "Pepper"

            var params = {
                // service_id: 'service_rmo478i',
                // template_id: 'template_8kl4rjy',
                // user_id: 'user_Jev9Y8AvSA6ZYUYQDPKDZ',
                //admin@agricultura.com 
                service_id: 'service_25vudju',
                template_id: 'template_dyzfo8v',
                user_id: 'user_Dq36tw6wIidms5s24Nseh',
                template_params: {
                    'CompanyName': CompanyName,
                    'ContactPerson': ContactPerson,
                    'PhoneNumber': PhoneNumber,
                    'EmailAddress': EmailAddress,
                    'RawMaterialUsage': RawMaterialUsage,
                    'PotentialProjectAmount': PotentialProjectAmount,
                    'PotentialProjectAmountSelect': PotentialProjectAmountSelect,
                    'YearlyDemandForecast': YearlyDemandForecast,
                    'YearlyDemandForecastSelect': YearlyDemandForecastSelect,
                    'Requirments': Requirments,
                    'PlantType': PlantType
                },
                accessToken: '025dbc916cfaf0749f6cafb682ee34c9'
            };

            axios({
                method: "post",
                url: `https://api.emailjs.com/api/v1.0/email/send`,
                data: params,
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "authenticationInfo": "c2971e7a9c3457a9726bc2c11edc9295"
                },
            })
                .then(response => {
                    this.setState({ isOpen: false })
                })
                .catch(error => {
                });
        }
    }

    validateForm = () => {
        let tempMandatory = (this.state.tempMandatory)
        map(this.state.mandatoryFields, eachElem => {
            if (isEmpty(this.state[eachElem]) && !includes(tempMandatory, eachElem))
                tempMandatory.push(eachElem)

            if (eachElem === "EmailAddress" && !isEmpty(this.state[eachElem]) && this.validateEmail(this.state[eachElem]) && !includes(tempMandatory, eachElem))
                tempMandatory.push(eachElem)
            if (eachElem === "FullName" && !isEmpty(this.state[eachElem]) && isEmpty(this.state[eachElem].trim()) && this.validateFullName(this.state[eachElem]) && !includes(tempMandatory, eachElem))
                tempMandatory.push(eachElem)
        })


        if (!this.state.ZaatarCheckbox &&
            !this.state.SummacCheckbox &&
            !this.state.BasilCheckbox &&
            !this.state.MintCheckbox &&
            !this.state.PepperCheckbox)
            tempMandatory.push("TypeOfPlants")

        this.setState({ tempMandatory, showErrorMessage: !isEmpty(tempMandatory) ? 'Some fields are empty or invalid' : '' })

        setTimeout(() => {
            this.setState({ showErrorMessage: '' })
        }, 8000)
        if (!isEmpty(tempMandatory)) return false
        else return true
    }

    validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return !re.test(String(email).toLowerCase());
    }

    validateFullName = (fullName) => {
        const re = /[a-zA-Z]+$/;
        return !re.test(String(fullName).toLowerCase());
    }

    toggleModal = val => event => {
        this.setState({
            isOpen: val === "open" ? true : false,
            CompanyName: '',
            ContactPerson: '',
            PhoneNumber: '',
            EmailAddress: '',
            RawMaterialUsage: '',
            PotentialProjectAmount: '',
            PotentialProjectAmountSelect: 'USD',
            YearlyDemandForecast: '',
            YearlyDemandForecastSelect: 'KG',
            Requirments: '',
            ZaatarCheckbox: false,
            SummacCheckbox: false,
            BasilCheckbox: false,
            MintCheckbox: false,
            PepperCheckbox: false,

            mandatoryFields: [
                'ContactPerson',
                'PhoneNumber',
                'EmailAddress',
                'RawMaterialUsage',
                'PotentialProjectAmount',
                'YearlyDemandForecast',
            ],

            tempMandatory: [],
            showErrorMessage: ''
        })
    }

    handleSelectorChange = name => event => {
        let value = event.target.value
        this.setState({ [name]: value })
    }

    render() {
        let { CompanyName, ContactPerson, PhoneNumber, EmailAddress,
            ZaatarCheckbox, SummacCheckbox, BasilCheckbox, MintCheckbox, PepperCheckbox, RawMaterialUsage,
            YearlyDemandForecast, PotentialProjectAmount,
            PotentialProjectAmountSelect, YearlyDemandForecastSelect,
            Requirments,
            tempMandatory, showErrorMessage, isOpen } = this.state
        return (
            <>
                <div id="BusinessApplication" className="row">
                    <div className="col-12">
                        <Button className="agri-background"
                            onClick={this.toggleModal("open")}>
                            BUSINESS APPLICATION</Button>
                    </div>
                </div>

                <Modal size="lg" isOpen={isOpen} className="lg"  >
                    <ModalHeader className="agri-color">
                        <h3>BUSINESS APPLICATION</h3>
                    </ModalHeader>
                    <ModalBody>
                        {showErrorMessage &&
                            <Alert className="alertModal" color="danger">
                                {showErrorMessage}
                            </Alert>
                        }
                        <Form>

                            <FormGroup>
                                <div className="row">

                                    <div className="col-12 col-xs-12 col-lg-6">
                                        <Label className={includes(tempMandatory, "CompanyName") ? "AlertRed" : ""} >Company Name</Label>
                                        <Input type="text" name="CompanyName" id="CompanyName"
                                            value={CompanyName ? CompanyName : ""}
                                            className={includes(tempMandatory, "CompanyName") ? "AlertRed" : ""}
                                            onChange={this.handleTextChange("CompanyName")} />
                                    </div>

                                    <div className="col-12 col-xs-12 col-lg-6">
                                        <Label className={includes(tempMandatory, "ContactPerson") ? "AlertRed" : ""}>Contact Person</Label>
                                        <Input type="text" name="ContactPerson" id="ContactPerson"
                                            value={ContactPerson ? ContactPerson : ""}
                                            className={includes(tempMandatory, "ContactPerson") ? "AlertRed" : ""}
                                            onChange={this.handleTextChange("ContactPerson")} />
                                    </div>

                                    <div className="col-12 col-xs-12 col-lg-6">
                                        <Label className={includes(tempMandatory, "PhoneNumber") ? "AlertRed" : ""}>Phone Number</Label>
                                        <Input type="number" name="PhoneNumber" id="PhoneNumber"
                                            value={PhoneNumber ? PhoneNumber : ""}
                                            className={includes(tempMandatory, "PhoneNumber") ? "AlertRed" : ""}
                                            onChange={this.handleTextChange("PhoneNumber")} />
                                    </div>

                                    <div className="col-12 col-xs-12 col-lg-6">
                                        <Label className={includes(tempMandatory, "EmailAddress") ? "AlertRed" : ""}>Email Address</Label>
                                        <Input type="email" name="EmailAddress" id="EmailAddress"
                                            value={EmailAddress ? EmailAddress : ""}
                                            className={includes(tempMandatory, "EmailAddress") ? "AlertRed" : ""}
                                            onChange={this.handleTextChange("EmailAddress")} />
                                    </div>
                                </div>
                            </FormGroup>
                            <h5 className={includes(tempMandatory, "TypeOfPlants") ? "AlertRedagri-color" : "agri-color"}
                            >type of plants</h5>

                            <FormGroup check className={includes(tempMandatory, "TypeOfPlants") ? "AlertRed" : ""}>
                                <div className="row">
                                    <div className="col-12 col-xs-12 col-lg-3">
                                        <Label >
                                            <Input type="radio" name="ZaatarCheckbox" id="ZaatarCheckbox"
                                                checked={ZaatarCheckbox}
                                                className={"AlertRed"}
                                                onChange={this.handleRadioChange("ZaatarCheckbox", ["SummacCheckbox", "BasilCheckbox", "MintCheckbox", "PepperCheckbox"])} />Zaatar
                                        </Label>
                                    </div>
                                    <div className="col-12 col-xs-12 col-lg-3">
                                        <Label >
                                            <Input type="radio" name="SummacCheckbox" id="SummacCheckbox"
                                                checked={SummacCheckbox}
                                                onChange={this.handleRadioChange("SummacCheckbox", ["ZaatarCheckbox", "BasilCheckbox", "MintCheckbox", "PepperCheckbox"])} />Summac
                                        </Label>
                                    </div>
                                    <div className="col-12 col-xs-12 col-lg-3">
                                        <Label >
                                            <Input type="radio" name="BasilCheckbox" id="BasilCheckbox"
                                                checked={BasilCheckbox}
                                                onChange={this.handleRadioChange("BasilCheckbox", ["ZaatarCheckbox", "SummacCheckbox", "MintCheckbox", "PepperCheckbox"])} />Basil
                                        </Label>
                                    </div>
                                    <div className="col-12 col-xs-12 col-lg-3">
                                        <Label >
                                            <Input type="radio" name="MintCheckbox" id="MintCheckbox"
                                                checked={MintCheckbox}
                                                onChange={this.handleRadioChange("MintCheckbox", ["ZaatarCheckbox", "SummacCheckbox", "BasilCheckbox", "PepperCheckbox"])} />Mint
                                        </Label>
                                    </div>
                                    <div className="col-12 col-xs-12 col-lg-3">
                                        <Label >
                                            <Input type="radio" name="PepperCheckbox" id="PepperCheckbox"
                                                checked={PepperCheckbox}
                                                onChange={this.handleRadioChange("PepperCheckbox", ["ZaatarCheckbox", "SummacCheckbox", "BasilCheckbox", "MintCheckbox"])} />Pepper
                                        </Label>
                                    </div>
                                </div>

                            </FormGroup>

                            <FormGroup>
                                <Label className={includes(tempMandatory, "RawMaterialUsage") ? "AlertRed" : ""}>Raw Material Usage</Label>
                                <Input type="text" name="RawMaterialUsage" id="RawMaterialUsage"
                                    value={RawMaterialUsage ? RawMaterialUsage : ""}
                                    className={includes(tempMandatory, "RawMaterialUsage") ? "AlertRed" : ""}
                                    onChange={this.handleTextChange("RawMaterialUsage")} />
                            </FormGroup>
                            <FormGroup>
                                <div className="row">

                                    <Label className={includes(tempMandatory, "PotentialProjectAmount") ? "AlertRed col-12 col-xs-12 col-lg-6" : "col-12 col-xs-12 col-lg-6"}>
                                        Potential Project Amount
                                    </Label>
                                    <div className="col-9">
                                        <Input type="text" name="PotentialProjectAmount" id="PotentialProjectAmount"
                                            value={PotentialProjectAmount ? PotentialProjectAmount : ""}
                                            className={includes(tempMandatory, "PotentialProjectAmount") ? "AlertRed" : ""}
                                            onChange={this.handleTextChange("PotentialProjectAmount")} />
                                    </div>
                                    <div className="col-3">
                                        <Input type="select"
                                            name="PotentialProjectAmountSelect"
                                            id="PotentialProjectAmountSelect"
                                            value={PotentialProjectAmountSelect}
                                            onChange={this.handleSelectorChange("PotentialProjectAmountSelect")}>
                                            <option>USD</option>
                                            <option>LBP</option>
                                        </Input>
                                    </div>
                                </div>

                                <div className="row  mt-1">
                                    <Label className={includes(tempMandatory, "YearlyDemandForecast") ? "AlertRed col-12 col-xs-12 col-lg-6" : "col-12 col-xs-12 col-lg-6"}>
                                        Yearly Demand Forecast
                                    </Label>
                                    <div className="col-9">
                                        <Input type="text" name="YearlyDemandForecast" id="YearlyDemandForecast"
                                            value={YearlyDemandForecast ? YearlyDemandForecast : ""}
                                            className={includes(tempMandatory, "YearlyDemandForecast") ? "AlertRed" : ""}
                                            onChange={this.handleTextChange("YearlyDemandForecast")} />
                                    </div>
                                    <div className="col-3">
                                        <Input type="select"
                                            name="YearlyDemandForecastSelect"
                                            id="YearlyDemandForecastSelect"
                                            value={YearlyDemandForecastSelect}
                                            onChange={this.handleSelectorChange("YearlyDemandForecastSelect")}>
                                            <option>KG</option>
                                        </Input>
                                    </div>
                                </div>
                            </FormGroup>

                            <FormGroup>
                                <Label className={includes(tempMandatory, "Message") ? "AlertRed" : ""}>Requirments</Label>
                                <Input type="textarea" name="Requirments" id="Requirments"
                                    value={Requirments ? Requirments : ""}
                                    className={includes(tempMandatory, "Requirments") ? "AlertRed" : ""}
                                    onChange={this.handleTextChange("Requirments")} />
                            </FormGroup>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button className="agri-background" onClick={this.handleApply}>Apply</Button>
                        <Button color="secondary" onClick={this.toggleModal("close")}>Cancel</Button>
                    </ModalFooter>
                </Modal>

            </>
        )
    }
}

export default BusinessApplication;

