import React from 'react';
// import axios from 'axios'
import {
    Button,
    Form,
    FormGroup,
    Label,
    Input,
    Alert,
} from 'reactstrap';
import { map, cloneDeep, isEmpty, remove, includes } from "lodash"

class NewClient extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            UserTypeClient: false,
            UserTypeProjectManager: false,
            ContactPerson: '',
            EmailAddress: '',
            PhoneNumber: '',
            UserName: '',
            Password: '',

            mandatoryFields: [
                'ContactPerson',
                'EmailAddress',
                'PhoneNumber',
                'UserName',
                'Password'
            ],

            tempMandatory: [],
            showErrorMessage: ''
        };
    }


    handleTextChange = val => event => {
        let tempMandatory = cloneDeep(this.state.tempMandatory)
        let { mandatoryFields } = this.state

        if (includes(mandatoryFields, val)) {
            tempMandatory = remove(tempMandatory, element => {
                return element !== val
            })
        }
        this.setState({ tempMandatory, [val]: event.target.value, showErrorMessage: '' })
    }


    handleRadioChange = (trueVal, falseVal) => event => {
        let tempMandatory = cloneDeep(this.state.tempMandatory)

        if (includes(tempMandatory, "userType")) {
            tempMandatory = remove(tempMandatory, element => {
                return element !== "userType"
            })
        }
        this.setState({ tempMandatory, [trueVal]: true, [falseVal]: false })
    }


    handleSubmit = () => {
        this.validateForm()
    }

    validateForm = () => {
        let tempMandatory = cloneDeep(this.state.tempMandatory)
        map(this.state.mandatoryFields, eachElem => {
            if (isEmpty(this.state[eachElem]) && !includes(tempMandatory, eachElem))
                tempMandatory.push(eachElem)


            if (eachElem === "EmailAddress" && !isEmpty(this.state[eachElem]) && this.validateEmail(this.state[eachElem]) && !includes(tempMandatory, eachElem))
                tempMandatory.push(eachElem)
            if ((eachElem === "ContactPerson" || eachElem === "ContactPerson")
                && !isEmpty(this.state[eachElem]) && isEmpty(this.state[eachElem].trim()) && this.validateFullName(this.state[eachElem]) && !includes(tempMandatory, eachElem))
                tempMandatory.push(eachElem)
        })

        if (!this.state.UserTypeClient && !this.state.UserTypeProjectManager)
            tempMandatory.push("UserType")

        this.setState({ tempMandatory, showErrorMessage: !isEmpty(tempMandatory) ? 'Some fields are empty or invalid' : '' })

        setTimeout(() => {
            this.setState({ showErrorMessage: '' })
        }, 8000)
        if (!isEmpty(tempMandatory)) return false
        else return true
    }

    validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return !re.test(String(email).toLowerCase());
    }

    validateFullName = (fullName) => {
        const re = /[a-zA-Z]+$/;
        return !re.test(String(fullName).toLowerCase());
    }

    render() {
        let { UserTypeClient,
            UserTypeProjectManager,
            ContactPerson,
            EmailAddress,
            PhoneNumber,
            UserName,
            Password,

            tempMandatory, showErrorMessage } = this.state
        return (
            <>
                {showErrorMessage &&
                    <Alert className="alertModal" color="danger">
                        {showErrorMessage}
                    </Alert>
                }
                <h3 className="agri-color">New User</h3>
                <Form>
                    <FormGroup check>
                        <div className="row">
                            <div className={includes(tempMandatory, "UserType") ? "AlertRed col-6" : "col-6"}>
                                <Label >User Type</Label>
                                <div className="row">
                                    <div className="col-6">
                                        <Label >
                                            <Input type="radio" name="UserTypeClient" id="UserTypeClient"
                                                checked={UserTypeClient}
                                                onChange={this.handleRadioChange("UserTypeClient", "UserTypeProjectManager")} />Client
                                        </Label>
                                    </div>
                                    <div className="col-6">
                                        <Label >
                                            <Input type="radio" name="UserTypeProjectManager" id="UserTypeProjectManager"
                                                checked={UserTypeProjectManager}
                                                onChange={this.handleRadioChange("UserTypeProjectManager", "UserTypeClient")} />Project Manager
                                        </Label>
                                    </div>
                                </div>
                            </div>
                           
                        </div>
                    </FormGroup>

                    <FormGroup>
                        <div className="row">

                        </div>
                    </FormGroup>

                    <FormGroup>
                        <div className="row">
                            <div className="col-6">
                                <Label className={includes(tempMandatory, "ContactPerson") ? "AlertRed" : ""}>Contact Person</Label>
                                <Input type="text" name="ContactPerson" id="ContactPerson"
                                    value={ContactPerson ? ContactPerson : ""}
                                    className={includes(tempMandatory, "ContactPerson") ? "AlertRed" : ""}
                                    onChange={this.handleTextChange("ContactPerson")} />
                            </div>
                        </div>
                    </FormGroup>

                    <FormGroup>
                        <div className="row">
                            <div className="col-6">
                                <Label className={includes(tempMandatory, "EmailAddress") ? "AlertRed" : ""}>Email Address</Label>
                                <Input type="email" name="EmailAddress" id="EmailAddress"
                                    value={EmailAddress ? EmailAddress : ""}
                                    className={includes(tempMandatory, "EmailAddress") ? "AlertRed" : ""}
                                    onChange={this.handleTextChange("EmailAddress")} />
                            </div>
                            <div className="col-6">
                                <Label className={includes(tempMandatory, "PhoneNumber") ? "AlertRed" : ""}>Phone Number</Label>
                                <Input type="number" name="PhoneNumber" id="PhoneNumber"
                                    value={PhoneNumber ? PhoneNumber : ""}
                                    className={includes(tempMandatory, "PhoneNumber") ? "AlertRed" : ""}
                                    onChange={this.handleTextChange("PhoneNumber")} />
                            </div>
                        </div>
                    </FormGroup>

                    <FormGroup>
                        <div className="row">
                            <div className="col-6">
                                <Label className={includes(tempMandatory, "UserName") ? "AlertRed" : ""}>User Name</Label>
                                <Input type="text" name="UserName" id="UserName"
                                    value={UserName ? UserName : ""}
                                    className={includes(tempMandatory, "UserName") ? "AlertRed" : ""}
                                    onChange={this.handleTextChange("UserName")} />
                            </div>
                            <div className="col-6">
                                <Label className={includes(tempMandatory, "Password") ? "AlertRed" : ""}>Password</Label>
                                <Input type="password" name="Password" id="Password"
                                    value={Password ? Password : ""}
                                    className={includes(tempMandatory, "Password") ? "AlertRed" : ""}
                                    onChange={this.handleTextChange("Password")} />
                            </div>
                        </div>
                    </FormGroup>

                </Form>
                <Button style={{ float: 'right' }} className="agri-background" onClick={this.handleSubmit}>Submit</Button>
            </>
        )
    }
}

export default NewClient;

