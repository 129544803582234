import React from 'react';

class Dashborad extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount = () => {
    }

    render() {
        return (
            <>
              Dashborad
            </>
        )
    }
}

export default Dashborad;

