import React from 'react';
import Footer from './Footer';
import {
  Button,
  FormGroup,
  Label,
  Input,
  Alert,
} from 'reactstrap';
import { map, cloneDeep, isEmpty, remove, includes } from "lodash"
import axios from 'axios';

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
      messageColor: "danger",
      ContactPerson: '',
      EmailAddress: '',
      PhoneNumber: '',
      messageToSend: '',
      mandatoryFields: [
        'ContactPerson',
        'EmailAddress',
        'PhoneNumber',
        'messageToSend'
      ],
      tempMandatory: [],
    };
  }

  handleChangePath = (url) => {
    this.props.history.push(url);
  }

  activateSuccessMsg = (message) => {
    this.setState({ message: message })

    setTimeout(() => {
      this.setState({ message: "" })
    }, 8000)
  }

  handleTextChange = val => event => {
    let tempMandatory = cloneDeep(this.state.tempMandatory)
    let { mandatoryFields } = this.state

    if (includes(mandatoryFields, val)) {
      tempMandatory = remove(tempMandatory, element => {
        return element !== val
      })
    }
    this.setState({ tempMandatory, [val]: event.target.value, message: '' })
  }

  handleSubmit = () => {
    if (this.validateForm() === true) {

      let { ContactPerson, EmailAddress, PhoneNumber, messageToSend } = this.state
      var params = {
        //info@agriculturaplus.com
        service_id: 'service_9qijlmk',
        template_id: 'template_4zsn4pe',
        user_id: 'user_VCHJmWKQPw5EmtG45GIvp',
        template_params: {
          'ContactPerson': ContactPerson,
          'EmailAddress': EmailAddress,
          'PhoneNumber': PhoneNumber,
          'messageToSend': messageToSend
        },
        accessToken: '56f0702786a2088ddfabd02abea936ca'
      };

      axios({
        method: "post",
        url: `https://api.emailjs.com/api/v1.0/email/send`,
        data: params,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "authenticationInfo": "c2971e7a9c3457a9726bc2c11edc9295"
        },
      })
        .then(response => {
          this.setState({
            ContactPerson: '',
            EmailAddress: '',
            PhoneNumber: '',
            messageToSend: '',
          })
        })
        .catch(error => {
        });

    }
  }

  validateForm = () => {
    let tempMandatory = cloneDeep(this.state.tempMandatory)
    map(this.state.mandatoryFields, eachElem => {
      if (isEmpty(this.state[eachElem]) && !includes(tempMandatory, eachElem))
        tempMandatory.push(eachElem)

      if (eachElem === "EmailAddress" && !isEmpty(this.state[eachElem]) && this.validateEmail(this.state[eachElem]) && !includes(tempMandatory, eachElem))
        tempMandatory.push(eachElem)
      if ((eachElem === "ContactPerson" || eachElem === "ContactPerson")
        && !isEmpty(this.state[eachElem]) && isEmpty(this.state[eachElem].trim()) && this.validateFullName(this.state[eachElem]) && !includes(tempMandatory, eachElem))
        tempMandatory.push(eachElem)
    })
    this.setState({ tempMandatory, message: !isEmpty(tempMandatory) ? 'Some fields are empty or invalid' : '' })

    setTimeout(() => {
      this.setState({ message: '' })
    }, 8000)
    if (!isEmpty(tempMandatory)) return false
    else return true

  }

  validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return !re.test(String(email).toLowerCase());
  }

  validateFullName = (fullName) => {
    const re = /[a-zA-Z]+$/;
    return !re.test(String(fullName).toLowerCase());
  }
  render() {
    let { message, messageColor, ContactPerson, EmailAddress, PhoneNumber, messageToSend, tempMandatory } = this.state
    return (
      <div id="ContactMainContainer" className="container" >
        {message &&
          <Alert style={{ width: "calc(100% - 47px)", position: "absolute", zIndex: "100" }} color={messageColor}>
            {message}
          </Alert>
        }
        <div className="MainImgAndTitleContainer">
          <div className="MainImgContainer" />
          <div className="TitleContainer">Contact us</div>
        </div>
        <div id="ContactContainer">
          <div id="ContactTopContainer" style={{ textAlign: "left" }}>
            <div className="row">
              <div className="col-sm-12 col-lg-6 contactBox">
                <h5 style={{fontSize:"22px", color:"#04423C", fontWeight:"bolder"}}><strong>Address</strong></h5>
                <p style={{fontSize:"20px", marginBottom:"0"}}>Jbeil Souq, Beainy Center,</p>
                <p style={{fontSize:"20px", marginBottom:"0"}}>Third Floor,</p>
                <p style={{fontSize:"20px"}}>Mount Lebanon, Lebanon</p>

                <h5 style={{fontSize:"22px", color:"#04423C", fontWeight:"bolder"}}><strong>Phone</strong> </h5>
                <a style={{fontSize:"20px", marginBottom:"0"}} href="tel:+96170484304">+961 70 484 304</a>

                <h5 style={{fontSize:"22px", color:"#04423C", fontWeight:"bolder", marginTop:"15px"}} ><strong>Email Address</strong></h5>
                <a  style={{fontSize:"20px", marginBottom:"0"}}href="mailto:info@agriculturaplus.com"> info@agriculturaplus.com</a>
              </div>
              <div className="col-sm-12 col-lg-6">
                <iframe style={{ border: 0, padding: "0 10px" }}
                  title="agricultura office location"
                  src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=34.12060319431294, 35.647108226260805&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                  width="100%" height="250" frameBorder="0" allowFullScreen="allowfullscreen"></iframe>
              </div>
            </div>
          </div>
          <div id="ContactBodyContainer">
            <div id="" style={{ maxWidth: "600px", margin:"auto", marginBottom: "50px" }}>
              <h5  style={{fontSize:"22px", color:"#04423C", fontWeight:"bolder", textAlign:"center", margin:"50px"}}>Let's Us Know How We Can Help You</h5>
              <FormGroup>
                <div className="row">
                  <div className="col-12" style={{marginTop:"20px"}}>
                    <Label className={includes(tempMandatory, "ContactPerson") ? "AlertRed" : ""}>Name</Label>
                    <Input type="text" name="ContactPerson" id="ContactPerson"
                    placeholder='Enter your name'
                      value={ContactPerson ? ContactPerson : ""}
                      className={includes(tempMandatory, "ContactPerson") ? "AlertRed" : ""}
                      onChange={this.handleTextChange("ContactPerson")} />
                  </div>
                  <div className="col-12" style={{marginTop:"20px"}}>
                    <Label className={includes(tempMandatory, "EmailAddress") ? "AlertRed" : ""}>Email</Label>
                    <Input type="email" name="EmailAddress" id="EmailAddress"
                    placeholder='Enter your email address'
                      value={EmailAddress ? EmailAddress : ""}
                      className={includes(tempMandatory, "EmailAddress") ? "AlertRed" : ""}
                      onChange={this.handleTextChange("EmailAddress")} />
                  </div>
                  <div className="col-12" style={{marginTop:"20px"}}>
                    <Label className={includes(tempMandatory, "PhoneNumber") ? "AlertRed" : ""}>Phone Number</Label>
                    <Input type="number" name="PhoneNumber" id="PhoneNumber"
                    placeholder='Enter your phone number'
                      value={PhoneNumber ? PhoneNumber : ""}
                      className={includes(tempMandatory, "PhoneNumber") ? "AlertRed" : ""}
                      onChange={this.handleTextChange("PhoneNumber")} />
                  </div>
                  <div className="col-12" style={{marginTop:"20px"}}>
                    <Label className={includes(tempMandatory, "messageToSend") ? "AlertRed" : ""}>Message</Label>
                    <Input type="textarea" name="messageToSend" id="messageToSend"
                    placeholder='Your message here....'
                      value={messageToSend ? messageToSend : ""}
                      className={includes(tempMandatory, "messageToSend") ? "AlertRed" : ""}
                      onChange={this.handleTextChange("messageToSend")} />
                  </div>

                </div>
              </FormGroup>
              <Button style={{ marginLeft: "40%" }} className="agri-background" onClick={this.handleSubmit}>Submit</Button>
            </div>

          </div>
          <Footer />
        </div>
      </div>
    )
  }

}

export default Contact;