import React from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavLink,
} from 'reactstrap';

class MainNavigator extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      toggleDropdown: false,
      selected:"/Home"
    }
  }

  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  toggleDropdown = () => {
    this.setState({ toggleDropdown: !this.state.toggleDropdown })
  }

  handleChangePath = (url) => {
    this.setState({selected: url})
    if (document.getElementById("companyProductsContainer"))
      document.getElementById("companyProductsContainer").scrollTo(0, 0)
    window.scrollTo(0, 0)
    this.props.history.push(url);
    this.setState({ isOpen: !this.state.isOpen })
  }

  handleLogout = () => {
    // userHasAuthenticated(false);
      this.props.setIsAuthenticated("")
    this.props.history.replace("/");
  }

  render() {
    return (
      <div id="MainNavContainer">
        <div id="NavContainerCentered">
          <Navbar color="light" light expand="md">

            <NavLink style={{ cursor: 'pointer' }} onClick={() => { this.handleChangePath("/Home") }} >
              <img alt="" src='/images/ac_logo.png' />
            </NavLink>
            <NavbarToggler onClick={this.toggle} style={{ float: "right" }} />
            <Collapse isOpen={this.state.isOpen} navbar>
              <Nav className="ml-auto" navbar>

                <NavLink onClick={() => { this.handleChangePath("/Home") }}>
                  <b style={this.state.selected ==="/Home"?{color:"#16472a"}:{}}>HOME</b>
                </NavLink>

                <NavLink onClick={() => { this.handleChangePath("/Products") }} >
                  <b style={this.state.selected ==="/Products"?{color:"#16472a"}:{}}>PRODUCTS</b>
                </NavLink>
                
                <NavLink onClick={() => { this.handleChangePath("/Services") }} >
                <b style={this.state.selected ==="/Services"?{color:"#16472a"}:{}}>Services</b>

                </NavLink>
                
                <NavLink onClick={() => { this.handleChangePath("/AGREEMENTS") }} >
                <b style={this.state.selected ==="/AGREEMENTS"?{color:"#16472a"}:{}}>ACTIVITIES</b>

                <b></b>
                </NavLink>

                <NavLink onClick={() => { this.handleChangePath("/OurStory") }} >
                <b style={this.state.selected ==="/OurStory"?{color:"#16472a"}:{}}>OUR STORY</b>

                   <b></b>
                </NavLink>

                <NavLink onClick={() => { this.handleChangePath("/About") }} >
                <b style={this.state.selected ==="/About"?{color:"#16472a"}:{}}>ABOUT</b>

                  <b></b>
                </NavLink>

                <NavLink onClick={() => { this.handleChangePath("/Contact") }} >
                <b style={this.state.selected ==="/Contact"?{color:"#16472a"}:{}}>CONTACT</b>

                   <b></b>
                </NavLink>

                {/* <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle className="navText" nav caret>
                    <b>ACTIVITIES</b>
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem onClick={() => { this.handleChangePath("/Partnerships") }}>PARTNERSHIPS</DropdownItem>
                    <DropdownItem onClick={() => { this.handleChangePath("/Agreements") }}>AGREEMENTS</DropdownItem>
                    <DropdownItem onClick={() => { this.handleChangePath("/Projects") }}>PROJECTS</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown> */}

                {/* {this.props.loginUser === "" ?
                  <NavLink className="navBarLoginButtom" onClick={() => { this.handleChangePath("/Login") }} >
                    <b>LOGIN</b>
                  </NavLink>
                  :
                  <div id="loginUserTag">
                    <Dropdown nav isOpen={this.state.toggleDropdown} toggle={this.toggleDropdown}>
                      <DropdownToggle nav caret>
                        {this.props.loginUser}
                      </DropdownToggle>
                      <DropdownMenu> */}
                {/* {userType === "A" ?
                  <DropdownItem>admin</DropdownItem>
                  :userType === "M" ?
                  <DropdownItem>manager</DropdownItem>
                  :
                  <DropdownItem>client</DropdownItem>
                } */}
                {/* <DropdownItem >
                          <NavLink onClick={() => { this.handleChangePath("/SystemManagement") }} >
                            admin
                          </NavLink>
                        </DropdownItem>
                        <DropdownItem onClick={ this.handleLogout}>Logout</DropdownItem>
                      </DropdownMenu>
                    </Dropdown> 
                  </div>
                } */}


              </Nav>
            </Collapse>
          </Navbar>


        </div>
      </div>
    );
  }
}

export default MainNavigator;