import React from 'react';
// import axios from 'axios'
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    FormGroup,
    Label,
    Input,
    Alert,
} from 'reactstrap';
import { map, cloneDeep, isEmpty, remove, includes } from "lodash"
import axios from 'axios';

class LandOwnerApplication extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            FirstName: '',
            FamilyName: '',
            Age: '',
            PhoneNumber: '',
            EmailAddress: '',
            LandLocation: '',
            Area: '',
            NbOfOwners: '',
            WaterArtesianWell: false,
            WaterGovernmentalInstallation: false,
            WaterCannula: false,
            WellAvailable: false,
            WellNotAvailable: false,
            ConciergeHouseAvailable: false,
            ConciergeHouseNotAvailable: false,
            WeatherConditionsWindy: false,
            WeatherConditionsSunny: false,
            WeatherConditionsCold: false,
            WeatherConditionsFreezing: false,
            WeatherConditionsHot: false,
            WeatherConditionsModerate: false,
            RelationPartnerShip: false,
            RelationRent: false,
            Concerns: '',

            NumberofPlantSpecies: '',
            PlantInput: '',
            ShrubInput: '',
            ClimberInput: '',
            TreeInput: '',
            PotentialInvestmentAmount: '',
            SuggestedShares: '',
            Liquidity: false,
            Prodution: false,
            ContributionInSaleYes: false,
            ContributionInSaleNo: false,

            DecisionMaker: false,
            SilentPartner: false,
            PlantRadio: false,
            ShrubRadio: false,
            ClimberRadio: false,

            willingToInvestYes: false,
            willingToInvestNo: true,

            isOpen: false,

            mandatoryFields: [
                "FirstName",
                "FamilyName",
                "Age",
                "PhoneNumber",
                "EmailAddress",
                "LandLocation",
                "Area",
                "NbOfOwners",
                "PotentialInvestmentAmount",
                "SuggestedShares",
            ],

            tempMandatory: [],
            showErrorMessage: ''
        };
    }

    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, false);
    }
    componentWillUnmount() {
        document.removeEventListener("keydown", this.escFunction, false);
    }

    escFunction = (event) => {
        if (event.key === "Escape") {
            this.setState({
                isOpen: false
            })
        }
    }

    handleTextChange = val => event => {
        let tempMandatory = cloneDeep(this.state.tempMandatory)
        let { mandatoryFields } = this.state
        if (includes(mandatoryFields, val)) {
            tempMandatory = remove(tempMandatory, element => {
                return element !== val
            })
        }
        this.setState({ tempMandatory, [val]: event.target.value, showErrorMessage: '' })
    }

    handleRadioChange = (trueVal, falseVal) => event => {
        let tempMandatory = cloneDeep(this.state.tempMandatory)
        let obj = {}

        if (trueVal === "WaterArtesianWell" || trueVal === "WaterGovernmentalInstallation" || trueVal === "WaterCannula") {
            if (includes(tempMandatory, "Water")) {
                tempMandatory = remove(tempMandatory, element => {
                    return element !== "Water"
                })
            }
        }
        if (trueVal === "WellAvailable" || trueVal === "WellNotAvailable") {
            if (includes(tempMandatory, "Well")) {
                tempMandatory = remove(tempMandatory, element => {
                    return element !== "Well"
                })
            }
        }
        if (trueVal === "ConciergeHouseAvailable" || trueVal === "ConciergeHouseNotAvailable") {
            if (includes(tempMandatory, "ConciergeHouse")) {
                tempMandatory = remove(tempMandatory, element => {
                    return element !== "ConciergeHouse"
                })
            }
        }
        if (trueVal === "WeatherConditionsWindy" || trueVal === "WeatherConditionsSunny" ||
            trueVal === "WeatherConditionsCold" || trueVal === "WeatherConditionsFreezing" ||
            trueVal === "WeatherConditionsHot" || trueVal === "WeatherConditionsModerate") {
            if (includes(tempMandatory, "WeatherConditions")) {
                tempMandatory = remove(tempMandatory, element => {
                    return element !== "WeatherConditions"
                })
            }
        }

        if (trueVal === "RelationPartnerShip" || trueVal === "RelationRent") {
            if (includes(tempMandatory, "Relation")) {
                tempMandatory = remove(tempMandatory, element => {
                    return element !== "Relation"
                })
            }
        }

        if (trueVal === "DecisionMaker" || trueVal === "SilentPartner") {
            if (includes(tempMandatory, "SuggestedRelation")) {
                tempMandatory = remove(tempMandatory, element => {
                    return element !== "SuggestedRelation"
                })
            }
        }
        if (trueVal === "Prodution" || trueVal === "Liquidity") {
            if (includes(tempMandatory, "SuggestedAssetDistribution")) {
                tempMandatory = remove(tempMandatory, element => {
                    return element !== "SuggestedAssetDistribution"
                })
            }
        }

        if (trueVal === "ContributionInSaleYes" || trueVal === "ContributionInSaleNo") {
            if (includes(tempMandatory, "ContributionInSale")) {
                tempMandatory = remove(tempMandatory, element => {
                    return element !== "ContributionInSale"
                })
            }
        }


        map(falseVal, eachElem => {
            return obj = { ...obj, [eachElem]: false }
        })

        this.setState({ ...this.state, ...obj, [trueVal]: true, tempMandatory })
    }

    handleSubmit = () => {
        if (this.validateForm() === true) {
            let { FirstName,
                FamilyName,
                Age,
                PhoneNumber,
                EmailAddress,
                LandLocation,
                Area,
                NbOfOwners,
                WaterArtesianWell,
                WaterGovernmentalInstallation,
                WaterCannula,
                WellAvailable,
                WellNotAvailable,
                ConciergeHouseAvailable,
                ConciergeHouseNotAvailable,
                WeatherConditionsWindy,
                WeatherConditionsSunny,
                WeatherConditionsCold,
                WeatherConditionsFreezing,
                WeatherConditionsHot,
                WeatherConditionsModerate,
                RelationPartnerShip,
                RelationRent,
                Concerns
            } = this.state

            let Water
            if (WaterCannula)
                Water = "Cannula"
            if (WaterArtesianWell)
                Water = "Artesian Well"
            if (WaterGovernmentalInstallation)
                Water = "Governmental Installation"

            let Well
            if (WellAvailable)
                Well = "Available"
            if (WellNotAvailable)
                Well = "Not Available"

            let ConciergeHouse
            if (ConciergeHouseAvailable)
                ConciergeHouse = "Available"
            if (ConciergeHouseNotAvailable)
                ConciergeHouse = "NotAvailable"

            let WeatherConditions
            if (WeatherConditionsWindy)
                WeatherConditions = "Windy"
            if (WeatherConditionsSunny)
                WeatherConditions = "Sunny"
            if (WeatherConditionsCold)
                WeatherConditions = "Cold"
            if (WeatherConditionsFreezing)
                WeatherConditions = "Freezing"
            if (WeatherConditionsHot)
                WeatherConditions = "Hot"
            if (WeatherConditionsModerate)
                WeatherConditions = "Moderate"

            let Relation
            if (RelationPartnerShip)
                Relation = "PartnerShip"
            if (RelationRent)
                Relation = "Rent"

            var params = {
                // service_id: 'service_rmo478i',
                // template_id: 'template_8kl4rjy',
                // user_id: 'user_Jev9Y8AvSA6ZYUYQDPKDZ',
                //admin@agricultura.com 
                service_id: 'service_25vudju',
                template_id: 'template_sa3tlan',
                user_id: 'user_Dq36tw6wIidms5s24Nseh',
                template_params: {
                    'FirstName': FirstName,
                    'FamilyName': FamilyName,
                    'Age': Age,
                    'PhoneNumber': PhoneNumber,
                    'EmailAddress': EmailAddress,
                    'LandLocation': LandLocation,
                    'Area': Area,
                    'NbOfOwners': NbOfOwners,
                    'Concerns': Concerns,
                    'Water': Water,
                    'Well': Well,
                    'ConciergeHouse': ConciergeHouse,
                    'WeatherConditions': WeatherConditions,
                    'Relation': Relation,
                },
                accessToken: 'c2971e7a9c3457a9726bc2c11edc9295'
            };

            axios({
                method: "post",
                url: `https://api.emailjs.com/api/v1.0/email/send`,
                data: params,
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "authenticationInfo": "c2971e7a9c3457a9726bc2c11edc9295"
                },
            })
                .then(response => {
                    this.setState({ isOpen: false })
                })
                .catch(error => {
                });
        }
    }

    validateForm = () => {
        let tempMandatory = cloneDeep(this.state.tempMandatory)

        map(this.state.mandatoryFields, eachElem => {

            if (isEmpty(this.state[eachElem]) && !includes(tempMandatory, eachElem))
                tempMandatory.push(eachElem)

            if (eachElem === "EmailAddress" && !isEmpty(this.state[eachElem]) && this.validateEmail(this.state[eachElem]) && !includes(tempMandatory, eachElem))
                tempMandatory.push(eachElem)
            if (eachElem === "FullName" && !isEmpty(this.state[eachElem]) && isEmpty(this.state[eachElem].trim()) && this.validateFullName(this.state[eachElem]) && !includes(tempMandatory, eachElem))
                tempMandatory.push(eachElem)
        })

        if (!this.state.WaterArtesianWell &&
            !this.state.WaterGovernmentalInstallation &&
            !this.state.WaterCannula)
            tempMandatory.push("Water")

        if (!this.state.WellAvailable &&
            !this.state.WellNotAvailable)
            tempMandatory.push("Well")

        if (!this.state.ConciergeHouseAvailable &&
            !this.state.ConciergeHouseNotAvailable)
            tempMandatory.push("ConciergeHouse")

        if (!this.state.WeatherConditionsWindy &&
            !this.state.WeatherConditionsSunny &&
            !this.state.WeatherConditionsCold &&
            !this.state.WeatherConditionsFreezing &&
            !this.state.WeatherConditionsHot &&
            !this.state.WeatherConditionsModerate)
            tempMandatory.push("WeatherConditions")

        if (!this.state.DecisionMaker &&
            !this.state.SilentPartner)
            tempMandatory.push("SuggestedRelation")

        if (!this.state.Liquidity &&
            !this.state.Prodution)
            tempMandatory.push("SuggestedAssetDistribution")

        if (!this.state.ContributionInSaleNo &&
            !this.state.ContributionInSaleYes)
            tempMandatory.push("ContributionInSale")

        if (!this.state.RelationPartnerShip &&
            !this.state.RelationRent)
            tempMandatory.push("Relation")

        this.setState({ tempMandatory, showErrorMessage: !isEmpty(tempMandatory) ? 'Some fields are empty or invalid' : '' })

        setTimeout(() => {
            this.setState({ showErrorMessage: '' })
        }, 8000)
        if (!isEmpty(tempMandatory)) return false
        else return true
    }

    validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return !re.test(String(email).toLowerCase());
    }

    validateFullName = (fullName) => {
        const re = /[a-zA-Z]+$/;
        return !re.test(String(fullName).toLowerCase());
    }

    toggleModal = val => event => {
        this.setState({
            isOpen: val === "open" ? true : false,
            FirstName: '',
            FamilyName: '',
            Age: '',
            PhoneNumber: '',
            EmailAddress: '',
            LandLocation: '',
            Area: '',
            NbOfOwners: '',
            WaterArtesianWell: false,
            WaterGovernmentalInstallation: false,
            WaterCannula: false,
            WellAvailable: false,
            WellNotAvailable: false,
            ConciergeHouseAvailable: false,
            ConciergeHouseNotAvailable: false,
            WeatherConditionsWindy: false,
            WeatherConditionsSunny: false,
            WeatherConditionsCold: false,
            WeatherConditionsFreezing: false,
            WeatherConditionsHot: false,
            WeatherConditionsModerate: false,
            RelationPartnerShip: false,
            RelationRent: false,
            Concerns: '',

            NumberofPlantSpecies:'',

            PlantInput: '',
            ShrubInput: '',
            ClimberInput: '',
            TreeInput: '',
            PotentialInvestmentAmount: '',
            SuggestedShares: '',
            Liquidity: false,
            Prodution: false,
            ContributionInSaleYes: false,
            ContributionInSaleNo: false,

            DecisionMaker: false,
            SilentPartner: false,
            PlantRadio: false,
            ShrubRadio: false,
            ClimberRadio: false,

            mandatoryFields: [
                "FirstName",
                "FamilyName",
                "Age",
                "PhoneNumber",
                "EmailAddress",
                "LandLocation",
                "Area",
                "NbOfOwners",
                "PotentialInvestmentAmount",
                "SuggestedShares",
            ],

            tempMandatory: [],
            showErrorMessage: ''
        })
    }

    handleSelectorChange = name => event => {
        let value = event.target.value
        this.setState({ [name]: value })
    }

    willingToInvest = val => event => {

        if (val === "willingToInvestYes")
            this.setState({ willingToInvestYes: true, willingToInvestNo: false })
        else
            this.setState({ willingToInvestYes: false, willingToInvestNo: true })
    }

    render() {
        let { FirstName,
            FamilyName,
            Age,
            PhoneNumber,
            EmailAddress,
            LandLocation,
            Area,
            NbOfOwners,
            WaterArtesianWell,
            WaterGovernmentalInstallation,
            WaterCannula,
            WellAvailable,
            WellNotAvailable,
            ConciergeHouseAvailable,
            ConciergeHouseNotAvailable,
            WeatherConditionsWindy,
            WeatherConditionsSunny,
            WeatherConditionsCold,
            WeatherConditionsFreezing,
            WeatherConditionsHot,
            WeatherConditionsModerate,
            RelationPartnerShip,
            RelationRent,
            Concerns,
            DecisionMaker,
            SilentPartner,
            PlantRadio,
            ShrubRadio,
            ClimberRadio,
            TreeRadio,
            PlantInput,
            ShrubInput,
            NumberofPlantSpecies,
            ClimberInput,
            TreeInput,
            PotentialInvestmentAmount,
            PotentialInvestmentAmountSelect,
            ContributionInSaleYes,
            ContributionInSaleNo,
            Liquidity,
            Prodution,
            SuggestedShares,
            willingToInvestYes,
            willingToInvestNo,
            tempMandatory, showErrorMessage, isOpen } = this.state
        return (
            <>
                <div id="LandOwnerApplication" className="row">
                    <div className="col-12">
                        <Button className="agri-background"
                            onClick={this.toggleModal("open")}
                            style={{ minWidth: "205px" }}>
                            {this.props.modalName}</Button>
                    </div>
                </div>

                <Modal size="xl" isOpen={isOpen} className="xl"  >
                    <ModalHeader className="agri-color">
                        <h3>{this.props.modalName}</h3>
                    </ModalHeader>
                    <ModalBody>
                        {showErrorMessage &&
                            <Alert className="alertModal" color="danger">
                                {showErrorMessage}
                            </Alert>
                        }
                        <div style={{ margin: "20px 50px" }}>
                            <Form>
                                <FormGroup>
                                    <div className="row">

                                        <div className="col-12 col-xs-12 col-lg-6">
                                            <Label className={includes(tempMandatory, "FirstName") ? "AlertRed" : ""}>First Name</Label>
                                            <Input type="text" name="FirstName" id="FirstName"
                                                value={FirstName ? FirstName : ""}
                                                className={includes(tempMandatory, "FirstName") ? "AlertRed" : ""}
                                                onChange={this.handleTextChange("FirstName")} />
                                        </div>

                                        <div className="col-12 col-xs-12 col-lg-6">
                                            <Label className={includes(tempMandatory, "FamilyName") ? "AlertRed" : ""}>Family Name</Label>
                                            <Input type="text" name="FamilyName" id="FamilyName"
                                                value={FamilyName ? FamilyName : ""}
                                                className={includes(tempMandatory, "FamilyName") ? "AlertRed" : ""}
                                                onChange={this.handleTextChange("FamilyName")} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-xs-12 col-lg-2">
                                            <Label className={includes(tempMandatory, "Age") ? "AlertRed" : ""}>Age</Label>
                                            <Input type="number" name="Age" id="Age"
                                                value={Age ? Age : ""}
                                                className={includes(tempMandatory, "Age") ? "AlertRed" : ""}
                                                onChange={this.handleTextChange("Age")} />
                                        </div>

                                        <div className="col-12 col-xs-12 col-lg-5">
                                            <Label className={includes(tempMandatory, "PhoneNumber") ? "AlertRed" : ""}>Phone Number</Label>
                                            <Input type="number" name="PhoneNumber" id="PhoneNumber"
                                                value={PhoneNumber ? PhoneNumber : ""}
                                                className={includes(tempMandatory, "PhoneNumber") ? "AlertRed" : ""}
                                                onChange={this.handleTextChange("PhoneNumber")} />
                                        </div>

                                        <div className="col-12 col-xs-12 col-lg-5">
                                            <Label className={includes(tempMandatory, "EmailAddress") ? "AlertRed" : ""}>Email Address</Label>
                                            <Input type="email" name="EmailAddress" id="EmailAddress"
                                                value={EmailAddress ? EmailAddress : ""}
                                                className={includes(tempMandatory, "EmailAddress") ? "AlertRed" : ""}
                                                onChange={this.handleTextChange("EmailAddress")} />
                                        </div>
                                    </div>
                                    <div className="row">

                                        <div className="col-12 col-xs-12 col-lg-5">
                                            <Label className={includes(tempMandatory, "LandLocation") ? "AlertRed" : ""}>Location</Label>
                                            <Input type="text" name="LandLocation" id="LandLocation"
                                                value={LandLocation ? LandLocation : ""}
                                                className={includes(tempMandatory, "LandLocation") ? "AlertRed" : ""}
                                                onChange={this.handleTextChange("LandLocation")} />
                                        </div>
                                        <div className="col-12 col-xs-12 col-lg-5">
                                            <Label className={includes(tempMandatory, "Area") ? "AlertRed" : ""}>Area</Label>
                                            <Input type="text" name="Area" id="Area"
                                                value={Area ? Area : ""}
                                                className={includes(tempMandatory, "Area") ? "AlertRed" : ""}
                                                onChange={this.handleTextChange("Area")} />
                                        </div>
                                        <div className="col-12 col-xs-12 col-lg-2">
                                            <Label className={includes(tempMandatory, "NbOfOwners") ? "AlertRed" : ""}>Nb Of Owners</Label>
                                            <Input type="number" name="NbOfOwners" id="NbOfOwners"
                                                value={NbOfOwners ? NbOfOwners : ""}
                                                className={includes(tempMandatory, "NbOfOwners") ? "AlertRed" : ""}
                                                onChange={this.handleTextChange("NbOfOwners")} />
                                        </div>
                                    </div>
                                </FormGroup>

                                <br></br>
                                <hr></hr>
                                <br></br>

                                <FormGroup check>
                                    <div className={includes(tempMandatory, "Water") ? "AlertRed row" : "row"}>

                                        <Label className="col-12 p-0 mt-2 mb-0">Water</Label>
                                        <div className="col-12 col-xs-12 col-lg-4">
                                            <p >
                                                <Input type="radio" name="WaterArtesianWell" id="WaterArtesianWell"
                                                    checked={WaterArtesianWell}
                                                    onChange={this.handleRadioChange("WaterArtesianWell", ["WaterGovernmentalInstallation", "WaterCannula"])} />Artesian Well
                                            </p>
                                        </div>
                                        <div className="col-12 col-xs-12 col-lg-4">
                                            <p >
                                                <Input type="radio" name="WaterGovernmentalInstallation" id="WaterGovernmentalInstallation"
                                                    checked={WaterGovernmentalInstallation}
                                                    onChange={this.handleRadioChange("WaterGovernmentalInstallation", ["WaterArtesianWell", "WaterCannula"])} />Governmental Installation
                                            </p>
                                        </div>

                                        <div className="col-12 col-xs-12 col-lg-4">

                                            <p >
                                                <Input type="radio" name="WaterCannula" id="WaterCannula"
                                                    checked={WaterCannula}
                                                    onChange={this.handleRadioChange("WaterCannula", ["WaterArtesianWell", "WaterGovernmentalInstallation"])} />Cannula
                                            </p>
                                        </div>

                                    </div>
                                </FormGroup>

                                <FormGroup check>
                                    <div className={includes(tempMandatory, "Well") ? "AlertRed row" : "row"}>
                                        <Label className="col-12 p-0 mt-2 mb-0">Well</Label>
                                        <div className="col-12 col-xs-12 col-lg-4">
                                            <p >
                                                <Input type="radio" name="WellAvailable" id="WellAvailable"
                                                    checked={WellAvailable}
                                                    onChange={this.handleRadioChange("WellAvailable", ["WellNotAvailable"])} />Available
                                            </p>
                                        </div>
                                        <div className="col-12 col-xs-12 col-lg-4">

                                            <p >
                                                <Input type="radio" name="WellNotAvailable" id="WellNotAvailable"
                                                    checked={WellNotAvailable}
                                                    onChange={this.handleRadioChange("WellNotAvailable", ["WellAvailable"])} />Not Available
                                            </p>
                                        </div>
                                    </div>

                                </FormGroup>

                                <FormGroup check>
                                    <div className={includes(tempMandatory, "ConciergeHouse") ? "AlertRed row" : "row"}>
                                        <Label className="col-12 p-0 mt-2 mb-0">Concierge</Label>
                                        <div className="col-12 col-xs-12 col-lg-4">
                                            <p >
                                                <Input type="radio" name="ConciergeHouseAvailable" id="ConciergeHouseAvailable"
                                                    checked={ConciergeHouseAvailable}
                                                    onChange={this.handleRadioChange("ConciergeHouseAvailable", ["ConciergeHouseNotAvailable"])} />  House Available
                                            </p>
                                        </div>

                                        <div className="col-12 col-xs-12 col-lg-4">

                                            <p >
                                                <Input type="radio" name="ConciergeHouseNotAvailable" id="ConciergeHouseNotAvailable"
                                                    checked={ConciergeHouseNotAvailable}
                                                    onChange={this.handleRadioChange("ConciergeHouseNotAvailable", ["ConciergeHouseAvailable"])} />House Not Available
                                            </p>
                                        </div>
                                    </div>

                                </FormGroup>

                                <FormGroup check>
                                    <div className={includes(tempMandatory, "WeatherConditions") ? "AlertRed row" : "row"}>
                                        <Label className="col-12 p-0 mt-2 mb-0">Weather Condition</Label>
                                        <div className="col-12 col-xs-12 col-lg-4">
                                            <p >
                                                <Input type="radio" name="WeatherConditionsWindy" id="WeatherConditionsWindy"
                                                    checked={WeatherConditionsWindy}
                                                    onChange={this.handleRadioChange(
                                                        "WeatherConditionsWindy",
                                                        ["WeatherConditionsSunny",
                                                            "WeatherConditionsCold",
                                                            "WeatherConditionsFreezing",
                                                            "WeatherConditionsHot",
                                                            "WeatherConditionsModerate"])} />Windy
                                            </p>
                                        </div>
                                        <div className="col-12 col-xs-12 col-lg-4">
                                            <p >
                                                <Input type="radio" name="WeatherConditionsSunny" id="WeatherConditionsSunny"
                                                    checked={WeatherConditionsSunny}
                                                    onChange={this.handleRadioChange(
                                                        "WeatherConditionsSunny",
                                                        ["WeatherConditionsWindy",
                                                            "WeatherConditionsCold",
                                                            "WeatherConditionsFreezing",
                                                            "WeatherConditionsHot",
                                                            "WeatherConditionsModerate"])} />Sunny
                                            </p>
                                        </div>
                                        <div className="col-12 col-xs-12 col-lg-4">
                                            <p >
                                                <Input type="radio" name="WeatherConditionsCold" id="WeatherConditionsCold"
                                                    checked={WeatherConditionsCold}
                                                    onChange={this.handleRadioChange(
                                                        "WeatherConditionsCold",
                                                        ["WeatherConditionsWindy",
                                                            "WeatherConditionsSunny",
                                                            "WeatherConditionsFreezing",
                                                            "WeatherConditionsHot",
                                                            "WeatherConditionsModerate"])} />Cold
                                            </p>
                                        </div>
                                        <div className="col-12 col-xs-12 col-lg-4">
                                            <p >
                                                <Input type="radio" name="WeatherConditionsFreezing" id="WeatherConditionsFreezing"
                                                    checked={WeatherConditionsFreezing}
                                                    onChange={this.handleRadioChange(
                                                        "WeatherConditionsFreezing",
                                                        ["WeatherConditionsWindy",
                                                            "WeatherConditionsSunny",
                                                            "WeatherConditionsCold",
                                                            "WeatherConditionsHot",
                                                            "WeatherConditionsModerate"])} />Freezing
                                            </p>
                                        </div>
                                        <div className="col-12 col-xs-12 col-lg-4">
                                            <p >
                                                <Input type="radio" name="WeatherConditionsHot" id="WeatherConditionsHot"
                                                    checked={WeatherConditionsHot}
                                                    onChange={this.handleRadioChange(
                                                        "WeatherConditionsHot",
                                                        ["WeatherConditionsWindy",
                                                            "WeatherConditionsSunny",
                                                            "WeatherConditionsCold",
                                                            "WeatherConditionsFreezing",
                                                            "WeatherConditionsModerate"])} />Hot
                                            </p>
                                        </div>
                                        <div className="col-12 col-xs-12 col-lg-4">
                                            <p >
                                                <Input type="radio" name="WeatherConditionsModerate" id="WeatherConditionsModerate"
                                                    checked={WeatherConditionsModerate}
                                                    onChange={this.handleRadioChange(
                                                        "WeatherConditionsModerate",
                                                        ["WeatherConditionsWindy",
                                                            "WeatherConditionsSunny",
                                                            "WeatherConditionsCold",
                                                            "WeatherConditionsFreezing",
                                                            "WeatherConditionsHot"])} />Moderate
                                            </p>
                                        </div>
                                    </div>

                                </FormGroup>

                                <br></br>
                                <hr></hr>
                                <br></br>

                                <FormGroup check>
                                    <div className={includes(tempMandatory, "Relation") ? "AlertRed row" : "row"}>
                                        <Label className="col-12 p-0 mt-2 mb-0" style={{ color: "#22B05B", fontSize: "18px" }}>Suggested Relation</Label>
                                        <div className="col-12 col-xs-12 col-lg-4">
                                            <p >
                                                <Input type="radio" name="RelationPartnerShip" id="RelationPartnerShip"
                                                    checked={RelationPartnerShip}
                                                    onChange={this.handleRadioChange("RelationPartnerShip", ["RelationRent"])} />PartnerShip
                                            </p>
                                        </div>
                                        <div className="col-12 col-xs-12 col-lg-4">

                                            <p >
                                                <Input type="radio" name="RelationRent" id="RelationRent"
                                                    value={RelationRent}
                                                    onChange={this.handleRadioChange("RelationRent", ["RelationPartnerShip"])} />Rent
                                            </p>
                                        </div>
                                    </div>

                                </FormGroup>

                                <br></br>
                                <hr></hr>
                                <br></br>

                                <FormGroup check style={{ textAlign: "center" }}>
                                    <div classname="row">
                                        <Label className="col-12 p-0 mt-2 mb-0" style={{ color: "#22B05B", fontSize: "18px" }}>Do you have a plot that you are willing to use in an agricultural project?</Label>
                                        <div className="col-12">
                                            <Button style={{ minWidth: "80px", marginRight: "15px" }} className={willingToInvestYes ? "agri-background" : ""} id="willingToInvestYes" onClick={this.willingToInvest("willingToInvestYes")}>YES</Button>
                                            <Button style={{ minWidth: "80px" }} className={willingToInvestNo ? "agri-background" : ""} id="willingToInvestNo" onClick={this.willingToInvest("willingToInvestNo")}>NO</Button>
                                        </div>
                                    </div>
                                </FormGroup>

                                <br></br>
                                <hr></hr>
                                <br></br>

                                {willingToInvestYes === true &&

                                    <>
                                        <FormGroup check>
                                            <div className={includes(tempMandatory, "SuggestedRelation") ? "AlertRed row" : "row"}>
                                                <Label className="col-12 p-0 mt-2 mb-0" style={{ color: "#22B05B", fontSize: "18px" }}>Suggested Relation</Label>
                                                <Label className="col-12 p-0 mt-2 mb-0">Decision Making</Label>
                                                <div className="col-12 col-xs-12 col-lg-4">
                                                    <p >
                                                        <Input type="radio" name="DecisionMaker" id="DecisionMaker"
                                                            checked={DecisionMaker}
                                                            onChange={this.handleRadioChange("DecisionMaker", ["SilentPartner"])} />Decision Maker
                                                    </p>
                                                </div>
                                                <div className="col-12 col-xs-12 col-lg-4">
                                                    <p >
                                                        <Input type="radio" name="SilentPartner" id="SilentPartner"
                                                            checked={SilentPartner}
                                                            onChange={this.handleRadioChange("SilentPartner", ["DecisionMaker"])} /> Silent Partner
                                                    </p>
                                                </div>
                                            </div>
                                        </FormGroup>

                                        <br></br>
                                        <hr></hr>
                                        <br></br>

                                        <FormGroup check>
                                            <div className="row">
                                                <Label className="col-12 p-0 mt-2 mb-0" style={{ color: "#22B05B", fontSize: "18px" }}>Suggested Plant</Label>
                                                <div className="col-12">
                                                    <div className="row">
                                                        <p className="col-3 p-0 mt-2 mb-0">Number of Plant Species</p>
                                                        <div className="col-9 mb-2">
                                                            <Input type="number" name="NumberofPlantSpecies" id="NumberofPlantSpecies"
                                                                value={NumberofPlantSpecies ? NumberofPlantSpecies : ""}
                                                                className={includes(tempMandatory, "NumberofPlantSpecies") ? "AlertRed" : ""}
                                                                onChange={this.handleTextChange("NumberofPlantSpecies")} />
                                                        </div>

                                                    </div>
                                                    <div className="row">
                                                        <Label className="col-12 p-0 mt-2 mb-0">Type</Label>
                                                        <div className="col-12 col-xs-12 col-lg-4">
                                                            <p >
                                                                <Input type="radio" name="PlantRadio" id="PlantRadio"
                                                                    checked={PlantRadio}
                                                                    onChange={this.handleRadioChange("PlantRadio", ["ShrubRadio", "ClimberRadio", "TreeRadio"])} />Plant
                                                            </p>
                                                        </div>
                                                        <div className="col-12 col-xs-12 col-lg-4">
                                                            <p >
                                                                <Input type="radio" name="ShrubRadio" id="ShrubRadio"
                                                                    checked={ShrubRadio}
                                                                    onChange={this.handleRadioChange("ShrubRadio", ["PlantRadio", "ClimberRadio", "TreeRadio"])} />Shrub
                                                            </p>
                                                        </div>
                                                        <div className="col-12 col-xs-12 col-lg-4">
                                                            <p >
                                                                <Input type="radio" name="ClimberRadio" id="ClimberRadio"
                                                                    checked={ClimberRadio}
                                                                    onChange={this.handleRadioChange("ClimberRadio", ["PlantRadio", "ShrubRadio", "TreeRadio"])} />Climber
                                                            </p>
                                                        </div>
                                                        <div className="col-12 col-xs-12 col-lg-4">
                                                            <p >
                                                                <Input type="radio" name="TreeRadio" id="TreeRadio"
                                                                    checked={TreeRadio}
                                                                    onChange={this.handleRadioChange("TreeRadio", ["PlantRadio", "ClimberRadio", "ShrubRadio"])} />Tree
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <Label className="col-12 p-0 mt-2 mb-0">Suggestions</Label>

                                                        <p className="col-3 p-0 mt-2 mb-0">Plant</p>
                                                        <div className="col-9 mb-2" >
                                                            <Input type="text" name="PlantInput" id="PlantInput"
                                                                value={PlantInput ? PlantInput : ""}
                                                                className={includes(tempMandatory, "PlantInput") ? "AlertRed" : ""}
                                                                onChange={this.handleTextChange("PlantInput")} />
                                                        </div>

                                                        <p className="col-3 p-0 mt-2 mb-0">Shrub</p>
                                                        <div className="col-9 mb-2">
                                                            <Input type="text" name="ShrubInput" id="ShrubInput"
                                                                value={ShrubInput ? ShrubInput : ""}
                                                                className={includes(tempMandatory, "ShrubInput") ? "AlertRed" : ""}
                                                                onChange={this.handleTextChange("ShrubInput")} />
                                                        </div>

                                                        <p className="col-3 p-0 mt-2 mb-0">Climber</p>
                                                        <div className="col-9 mb-2">
                                                            <Input type="text" name="ClimberInput" id="ClimberInput"
                                                                value={ClimberInput ? ClimberInput : ""}
                                                                className={includes(tempMandatory, "ClimberInput") ? "AlertRed" : ""}
                                                                onChange={this.handleTextChange("ClimberInput")} />
                                                        </div>

                                                        <p className="col-3 p-0 mt-2 mb-0">Tree</p>
                                                        <div className="col-9 mb-2">
                                                            <Input type="text" name="TreeInput" id="TreeInput"
                                                                value={TreeInput ? TreeInput : ""}
                                                                className={includes(tempMandatory, "TreeInput") ? "AlertRed" : ""}
                                                                onChange={this.handleTextChange("TreeInput")} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </FormGroup>

                                        <br></br>
                                        <hr></hr>
                                        <br></br>

                                        <FormGroup>
                                            <div className="row">

                                                <Label className="col-3">
                                                    Potential Investment Amount
                                                </Label>
                                                <div className="col-6">
                                                    <Input type="number" name="PotentialInvestmentAmount" id="PotentialInvestmentAmount"
                                                        value={PotentialInvestmentAmount ? PotentialInvestmentAmount : ""}
                                                        className={includes(tempMandatory, "PotentialInvestmentAmount") ? "AlertRed" : ""}
                                                        onChange={this.handleTextChange("PotentialInvestmentAmount")} />
                                                </div>
                                                <div className="col-3">
                                                    <Input type="select"
                                                        name="PotentialInvestmentAmountSelect"
                                                        id="PotentialInvestmentAmountSelect"
                                                        value={PotentialInvestmentAmountSelect}
                                                        onChange={this.handleSelectorChange("PotentialInvestmentAmountSelect")}>
                                                        <option>USD</option>
                                                        <option>LBP</option>
                                                    </Input>
                                                </div>
                                            </div>
                                        </FormGroup>

                                        <br></br>
                                        <hr></hr>
                                        <br></br>

                                        <FormGroup check>
                                            <div className={includes(tempMandatory, "ContributionInSale") ? "AlertRed row" : "row"}>
                                                <Label className="col-12 p-0 mt-2 mb-0">Contribution In Sale</Label>
                                                <div className="col-12 col-xs-12 col-lg-4">
                                                    <p >
                                                        <Input type="radio" name="ContributionInSaleYes" id="ContributionInSaleYes"
                                                            checked={ContributionInSaleYes}
                                                            onChange={this.handleRadioChange("ContributionInSaleYes", ["ContributionInSaleNo"])} />Yes
                                                    </p>
                                                </div>
                                                <div className="col-12 col-xs-12 col-lg-4">
                                                    <p >
                                                        <Input type="radio" name="ContributionInSaleNo" id="ContributionInSaleNo"
                                                            checked={ContributionInSaleNo}
                                                            onChange={this.handleRadioChange("ContributionInSaleNo", ["ContributionInSaleYes"])} />No
                                                    </p>
                                                </div>
                                            </div>
                                        </FormGroup>

                                        <FormGroup check>
                                            <div className={includes(tempMandatory, "SuggestedAssetDistribution") ? "AlertRed row" : "row"}>
                                                <Label className="col-12 p-0 mt-2 mb-0">Suggested Asset Distribution</Label>
                                                <div className="col-12 col-xs-12 col-lg-4">
                                                    <p >
                                                        <Input type="radio" name="Liquidity" id="Liquidity"
                                                            checked={Liquidity}
                                                            onChange={this.handleRadioChange("Liquidity", ["Prodution"])} />Liquidity
                                                    </p>
                                                </div>
                                                <div className="col-12 col-xs-12 col-lg-4">
                                                    <p >
                                                        <Input type="radio" name="Prodution" id="Liquidity"
                                                            checked={Prodution}
                                                            onChange={this.handleRadioChange("Prodution", ["Liquidity"])} />Prodution
                                                    </p>
                                                </div>
                                            </div>
                                        </FormGroup>

                                        <FormGroup>
                                            <div className="row">

                                                <Label className="col-3">
                                                    Suggested Shares
                                                </Label>
                                                <div className="col-6">
                                                    <Input type="text" name="SuggestedShares" id="SuggestedShares"
                                                        value={SuggestedShares ? SuggestedShares : ""}
                                                        className={includes(tempMandatory, "SuggestedShares") ? "AlertRed" : ""}
                                                        onChange={this.handleTextChange("SuggestedShares")} />
                                                </div>
                                                <div className="col-3">
                                                    <Input type="select"
                                                        value={"%"}>
                                                        <option>%</option>
                                                    </Input>
                                                </div>
                                            </div>
                                        </FormGroup>

                                        <br></br>
                                        <hr></hr>
                                        <br></br>
                                    </>
                                }

                                <FormGroup>
                                    <Label className={includes(tempMandatory, "Message") ? "AlertRed" : ""} style={{ color: "#22B05B", fontSize: "18px" }}>Concerns</Label>
                                    <Input type="textarea" name="Concerns" id="Concerns"
                                        value={Concerns ? Concerns : ""}
                                        className={includes(tempMandatory, "Concerns") ? "AlertRed" : ""}
                                        onChange={this.handleTextChange("Concerns")} />
                                </FormGroup>
                            </Form>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button className="agri-background" onClick={this.handleSubmit}>Submit</Button>
                        <Button className="secondary" onClick={this.toggleModal("close")}>cancel</Button>
                    </ModalFooter>
                </Modal>

            </>
        )
    }
}

export default LandOwnerApplication;