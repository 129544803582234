import React from 'react';
import { Router, Switch, Route } from "react-router-dom";
import AdminNavigator from './AdminNavigator';
import ProjectList from './ProjectList';
import NewClient from './NewClient';
import ClientsList from './ClientsList';
import NewLand from './NewLand';
import NewProject from './NewProject';
import LandsList from './LandsList';

import { createHashHistory } from 'history';

const history = createHashHistory()

class SystemManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount = () => {
    }

    render() {
        return (
            <>
            <div id="SystemManagementAdminNavigator">
              <AdminNavigator history={history} />
            </div>
            <div id="SystemManagementMainBodyContainer"  >
              <Router history={history}>
                <Switch>
                  <Route path="/SystemManagement/ProjectList" component={ProjectList} />
                  <Route path="/SystemManagement/NewClient" component={NewClient} />
                  <Route path="/SystemManagement/ClientsList" component={ClientsList} />
                  <Route path="/SystemManagement/NewLand" component={NewLand} />
                  <Route path="/SystemManagement/NewProject" component={NewProject} />
                  <Route path="/SystemManagement/LandsList" component={LandsList} />
        
                  <Route path="/SystemManagement" exact component={ProjectList} />
                </Switch>
              </Router>
            </div>
            <div id="AppFooterContainer">
            </div>
          </>
        )
    }
}

export default SystemManagement;
