import React from 'react';
import {
  Card, CardHeader, CardBody,
  CardTitle, CardText
} from 'reactstrap';
import Footer from './Footer';

class Projects extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {

    return (
      <div id="ProjectsMainContainer" className="container" >

        <div className="MainImgAndTitleContainer">
          <div className="MainImgContainer" />
          <div className="TitleContainer">Projects</div>
        </div>

        <div id="ProjectsContainer">
          <div id="ProjectsTopContainer" className="agri-color"  >
          <h5>Agricultura Plus is monitoring 4 agricultural projects. these projects were established within 1 year as the company is still expanding and working on new projects
          
          </h5></div>
          <div id="ProjectsBodyContainer">
          <div className="row" style={{margin:"0"}}>
              <h3 className="agri-color">Projects</h3>
            </div>
            <div className="row" id="ProjectsCardsContainer">
              <div className="col-sm-12 col-md-3">
                <Card>
                  <CardHeader tag="h5">Initial Project</CardHeader>
                  <CardBody>
                    <CardTitle >Location</CardTitle>
                    <CardText tag="h5">Kfarhata - Koura</CardText>
                    <CardTitle >Area</CardTitle>
                    <CardText tag="h5">10.000m<sup>2</sup></CardText>
                    <CardTitle >Cultivation</CardTitle>
                    <CardText tag="h5">70.000 Thyme Plants</CardText>
                    <CardText tag="h5"><br/></CardText>
                  </CardBody>
                </Card>
              </div>

              <div className="col-sm-12 col-md-3">
                <Card>
                  <CardHeader tag="h5">Btaaboura Project</CardHeader>
                  <CardBody>
                    <CardTitle >Location</CardTitle>
                    <CardText tag="h5">Btaaboura - Koura</CardText>
                    <CardTitle >Area</CardTitle>
                    <CardText tag="h5">12.000m<sup>2</sup></CardText>
                    <CardTitle >Cultivation</CardTitle>
                    <CardText tag="h5">70.000 Thyme Plants</CardText>
                    <CardText tag="h5">200 Summac Shrubs</CardText>
                  </CardBody>
                </Card>
              </div>

              <div className="col-sm-12 col-md-3">
                <Card>
                  <CardHeader tag="h5">Kelbata Project</CardHeader>
                  <CardBody>
                    <CardTitle >Location</CardTitle>
                    <CardText tag="h5">Kelbata - Koura</CardText>
                    <CardTitle >Area</CardTitle>
                    <CardText tag="h5">18.000m<sup>2</sup></CardText>
                    <CardTitle >Cultivation</CardTitle>
                    <CardText tag="h5">100.000 Thyme Plants</CardText>
                    <CardText tag="h5"><br/></CardText>
                  </CardBody>
                </Card>
              </div>

              <div className="col-sm-12 col-md-3">
                <Card>
                  <CardHeader tag="h5">Mejdlaya Project</CardHeader>
                  <CardBody>
                    <CardTitle >Location</CardTitle>
                    <CardText tag="h5">Mejdlaya - Zgharta</CardText>
                    <CardTitle >Area</CardTitle>
                    <CardText tag="h5">20.000m<sup>2</sup></CardText>
                    <CardTitle >Cultivation</CardTitle>
                    <CardText tag="h5">4.000 Summac Shrubs</CardText>
                    <CardText tag="h5"><br/></CardText>
                  </CardBody>
                </Card>
              </div>

            </div>
        
          
            <div className="row"  style={{marginTop:"20px"}}>
              <h2 style={{fontWeight:"bold", width:"100%", borderRadius:"15px", textAlign:"center", color:"#fff", padding:"15px", backgroundColor:"#22b05b"}}>$50.000 Investments / 50.000 m<sup>2</sup></h2>
            </div>
            </div>

          <Footer />

        </div>
      </div>
    )
  }

}

export default Projects;

