import React from 'react';
import Footer from './Footer';
import { map } from 'lodash';

class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.leadershipArray = [
      {
        image: '/images/about/leadership-1.png',
        name: "Rami Saba",
        position: "Operation Manager",
      },
      {
        image: '/images/about/leadership-2.png',
        name: "Rabih Nefaa",
        position: "R&D manager",
      },
      {
        image: '/images/about/leadership-3.png',
        name: "Jad Njeim",
        position: "Sales Manager",
      },
      {
        image: '/images/about/leadership-4.png',
        name: "Gabriel Ghazal",
        position: "Agricultural Consultant",
      },
    ]
    this.investorsArray = [
      {
        image: '/images/about/leadership-1.png',
        name: "Ahmad Rifi",
      },
      {
        image: '/images/about/leadership-3.png',
        name: "Rami Jabbour",
      },
      {
        image: '/images/about/leadership-4.png',
        name: "Rami Zgheib",
      },
    ]

  }

  render() {
    return (
      <div id="AboutMainContainer" className="container" >
        <div className="MainImgAndTitleContainer">
          <div className="MainImgContainer" />
          <div className="TitleContainer">About us</div>
        </div>
        <div id="AboutContainer">
          <div id="AboutTopContainer" >
            <p style={{ color:"#04423C", fontWeight:"bolder", padding:"70px", fontSize:"3vmin"}}>Our professional team is ready to transform your company's investment into a
              beneficial agreement that will work efficiently in terms of profitability and consistency.</p>

          </div>
          <div id="AboutBodyContainer">
            
            <div className="row" style={{textAlign: "-webkit-center"}}>
              <h3 style={{ color:"#04423C", fontWeight:"bolder", fontSize:"38px", marginTop:"40px"}}>Leadership</h3>
              {
                map(this.leadershipArray, (eachElem, key) => {
                  return (
                    <div className='col-12 col-xs-12 col-md-6 col-lg-3' key={key}>
                      <div>
                        <img alt="" src={eachElem.image} style={{ height: "235px", width: "156px", objectFit: "contain" }} />
                      </div>
                      <h3 style={{ color:"#04423C", fontWeight:"bolder", fontSize:"29px"}}>{eachElem.name}</h3>
                      <div style={{ color:"#22B05B", fontSize:"24px"}}>{eachElem.position}</div>
                    </div>
                  )
                })
              }
            </div>
            
            <div className="row" style={{textAlign: "-webkit-center", marginBottom:"100px"}}>
              <h3  style={{ color:"#04423C", fontWeight:"bolder", fontSize:"38px", margin:"50px 20px"}}>Investor</h3>
              {
                map(this.investorsArray, (eachElem, key) => {
                  return (
                    <div className='col-12 col-xs-12 col-md-6 col-lg-4' key={key}>
                      <div>
                        <img alt="" src={eachElem.image} style={{ height: "235px", width: "156px", objectFit: "contain" }} />
                      </div>
                      <div style={{ color:"#04423C", fontWeight:"bolder", fontSize:"29px"}}>{eachElem.name}</div>
                    </div>
                  )
                })
              }
            </div>

          </div>
          {/* <div className="row">
              <div className="col-3">
              </div>
              <div className="col-9">
                <h5>Rami Saba</h5>
                <h6>R&D Manager</h6>
                Agricultura Plus is establishing with several NGO's to make sure that the company is following operational sustainability and to help near communities grow.
              </div>
            </div>
            <div className="row">
              <div className="col-3">
              </div>
              <div className="col-9">
                <h5>Rabih Nefaa</h5>
                <h6>Operation Manager</h6>
                Our professional team is ready to transform your company's investment into a beneficial agreement that will work efficiently in terms of profitability and consistency.
              </div>
            </div>
            <div className="row">
              <div className="col-3">
              </div>
              <div className="col-9">
                <h5>Jad Njeim</h5>
                <h6>Sales Manager</h6>
                Businesses and organizations are now able to outsource agriculture services in order to guarantee low cost supply, quality control consistency, and guaranteed availability.
              </div>
            </div>
            <div className="row">
              <div className="col-3">
              </div>
              <div className="col-9">
                <h5>Gabriel Ghazal</h5>
                <h6>Agriculture Consultant</h6>
                Agricultura Plus is establishing partnerships with several NGO's to make sure that the company is following operational sustainability and to help near communities grow.
              </div>
            </div>*/}
          <Footer />
        </div>
      </div>

    )
  }

}

export default About;

