import React from 'react';
// import axios from 'axios'
import {
    Button,
    Form,
    FormGroup,
    Label,
    Input,
    Alert,
} from 'reactstrap';
import { map, cloneDeep, isEmpty, remove, includes } from "lodash"

class NewLand extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ContactPerson: '',
            PhoneNumber: '',
            EmailAddress: '',
            LandLocation: '',
            Area: '',
            NbOfOwners: '',
            WaterArtesianWell: false,
            WaterGovernmentalInstallation: false,
            WaterCannula: false,
            WellAvailable: false,
            WellNotAvailable: false,
            ConciergeHouseAvailable: false,
            ConciergeHouseNotAvailable: false,
            WeatherConditionsWindy: false,
            WeatherConditionsSunny: false,
            WeatherConditionsCold: false,
            WeatherConditionsFreezing: false,
            WeatherConditionsHot: false,
            WeatherConditionsModerate: false,
            RelationPartnerShip: false,
            RelationRent: false,
            Concerns: '',

            mandatoryFields: [
                'ContactPerson',
                'PhoneNumber',
                'EmailAddress',
                'LandLocation',
                'Area',
                'NbOfOwners',
            ],

            tempMandatory: [],
            showErrorMessage: ''
        };
    }

    handleTextChange = val => event => {
        let tempMandatory = cloneDeep(this.state.tempMandatory)
        let { mandatoryFields } = this.state

        if (includes(mandatoryFields, val)) {
            tempMandatory = remove(tempMandatory, element => {
                return element !== val
            })
        }
        this.setState({ tempMandatory, [val]: event.target.value, showErrorMessage: '' })
    }

    handleRadioChange = (trueVal, falseVal) => event => {
        let tempMandatory = cloneDeep(this.state.tempMandatory)
        let obj = {}
        if (trueVal === "WaterArtesianWell" || trueVal === "WaterGovernmentalInstallation" || trueVal === "WaterCannula") {
            if (includes(tempMandatory, "Water")) {
                tempMandatory = remove(tempMandatory, element => {
                    return element !== "Water"
                })
            }
        }

        if (trueVal === "WellAvailable" || trueVal === "WellNotAvailable") {
            if (includes(tempMandatory, "Well")) {
                tempMandatory = remove(tempMandatory, element => {
                    return element !== "Well"
                })
            }
        }

        if (trueVal === "ConciergeHouseAvailable" || trueVal === "ConciergeHouseNotAvailable") {
            if (includes(tempMandatory, "ConciergeHouse")) {
                tempMandatory = remove(tempMandatory, element => {
                    return element !== "ConciergeHouse"
                })
            }
        }

        if (trueVal === "WeatherConditionsWindy" || trueVal === "WeatherConditionsSunny" || trueVal === "WeatherConditionsCold"
            || trueVal === "WeatherConditionsFreezing" || trueVal === "WeatherConditionsHot" || trueVal === "WeatherConditionsModerate") {
            if (includes(tempMandatory, "WeatherConditions")) {
                tempMandatory = remove(tempMandatory, element => {
                    return element !== "WeatherConditions"
                })
            }
        }

        if (trueVal === "RelationPartnerShip" || trueVal === "RelationRent") {
            if (includes(tempMandatory, "Relation")) {
                tempMandatory = remove(tempMandatory, element => {
                    return element !== "Relation"
                })
            }
        }

        map(falseVal, eachElem => {
            return obj = { ...obj, [eachElem]: false }
        })

        this.setState({ ...this.state, ...obj, [trueVal]: true, tempMandatory })
    }

    handleSubmit = () => {
        this.validateForm()
    }

    validateForm = () => {
        let tempMandatory = cloneDeep(this.state.tempMandatory)

        map(this.state.mandatoryFields, eachElem => {
            if (isEmpty(this.state[eachElem]) && !includes(tempMandatory, eachElem))
                tempMandatory.push(eachElem)


            if (eachElem === "EmailAddress" && !isEmpty(this.state[eachElem]) && this.validateEmail(this.state[eachElem]) && !includes(tempMandatory, eachElem))
                tempMandatory.push(eachElem)
            if (eachElem === "FullName" && !isEmpty(this.state[eachElem]) && isEmpty(this.state[eachElem].trim()) && this.validateFullName(this.state[eachElem]) && !includes(tempMandatory, eachElem))
                tempMandatory.push(eachElem)
        })
        if (!this.state.WaterArtesianWell &&
            !this.state.WaterGovernmentalInstallation &&
            !this.state.WaterCannula)
            tempMandatory.push("Water")

        if (!this.state.WellAvailable &&
            !this.state.WellNotAvailable)
            tempMandatory.push("Well")

        if (!this.state.ConciergeHouseAvailable &&
            !this.state.ConciergeHouseNotAvailable)
            tempMandatory.push("ConciergeHouse")

        if (!this.state.WeatherConditionsWindy &&
            !this.state.WeatherConditionsSunny &&
            !this.state.WeatherConditionsCold &&
            !this.state.WeatherConditionsFreezing &&
            !this.state.WeatherConditionsHot &&
            !this.state.WeatherConditionsModerate)
            tempMandatory.push("WeatherConditions")

        if (!this.state.RelationPartnerShip &&
            !this.state.RelationRent)
            tempMandatory.push("Relation")


        this.setState({ tempMandatory, showErrorMessage: !isEmpty(tempMandatory) ? 'Some fields are empty or invalid' : '' })

        setTimeout(() => {
            this.setState({ showErrorMessage: '' })
        }, 8000)
        if (!isEmpty(tempMandatory)) return false
        else return true
    }

    validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return !re.test(String(email).toLowerCase());
    }

    validateFullName = (fullName) => {
        const re = /[a-zA-Z]+$/;
        return !re.test(String(fullName).toLowerCase());
    }

    render() {
        let { ContactPerson,
            PhoneNumber,
            EmailAddress,
            LandLocation,
            Area,
            NbOfOwners,
            WaterArtesianWell,
            WaterGovernmentalInstallation,
            WaterCannula,
            WellAvailable,
            WellNotAvailable,
            ConciergeHouseAvailable,
            ConciergeHouseNotAvailable,
            WeatherConditionsWindy,
            WeatherConditionsSunny,
            WeatherConditionsCold,
            WeatherConditionsFreezing,
            WeatherConditionsHot,
            WeatherConditionsModerate,
            RelationPartnerShip,
            RelationRent,
            Concerns,
            tempMandatory, showErrorMessage } = this.state
        return (
            <>
                {showErrorMessage &&
                    <Alert color="danger">
                        {showErrorMessage}
                    </Alert>
                }
                <h3 className="agri-color">New Land</h3>
                <Form>

                    <FormGroup>
                        <div className="row">

                            <div className="col-6">
                                <Label className={includes(tempMandatory, "ContactPerson") ? "AlertRed" : ""}>Contact Person</Label>
                                <Input type="text" name="ContactPerson" id="ContactPerson"
                                    value={ContactPerson ? ContactPerson : ""}
                                    className={includes(tempMandatory, "ContactPerson") ? "AlertRed" : ""}
                                    onChange={this.handleTextChange("ContactPerson")} />
                            </div>

                            <div className="col-6">
                                <Label className={includes(tempMandatory, "PhoneNumber") ? "AlertRed" : ""}>Phone Number</Label>
                                <Input type="number" name="PhoneNumber" id="PhoneNumber"
                                    value={PhoneNumber ? PhoneNumber : ""}
                                    className={includes(tempMandatory, "PhoneNumber") ? "AlertRed" : ""}
                                    onChange={this.handleTextChange("PhoneNumber")} />
                            </div>

                            <div className="col-6">
                                <Label className={includes(tempMandatory, "EmailAddress") ? "AlertRed" : ""}>Email Address</Label>
                                <Input type="email" name="EmailAddress" id="EmailAddress"
                                    value={EmailAddress ? EmailAddress : ""}
                                    className={includes(tempMandatory, "EmailAddress") ? "AlertRed" : ""}
                                    onChange={this.handleTextChange("EmailAddress")} />
                            </div>

                            <div className="col-6">
                                <Label className={includes(tempMandatory, "LandLocation") ? "AlertRed" : ""}>Location</Label>
                                <Input type="text" name="LandLocation" id="LandLocation"
                                    value={LandLocation ? LandLocation : ""}
                                    className={includes(tempMandatory, "LandLocation") ? "AlertRed" : ""}
                                    onChange={this.handleTextChange("LandLocation")} />
                            </div>

                            <div className="col-6">
                                <Label className={includes(tempMandatory, "Area") ? "AlertRed" : ""} >Area</Label>
                                <Input type="text" name="Area" id="Area"
                                    value={Area ? Area : ""}
                                    className={includes(tempMandatory, "Area") ? "AlertRed" : ""}
                                    onChange={this.handleTextChange("Area")} />
                            </div>

                            <div className="col-6">
                                <Label className={includes(tempMandatory, "NbOfOwners") ? "AlertRed" : ""}>Nb Of Owners</Label>
                                <Input type="number" name="NbOfOwners" id="NbOfOwners"
                                    value={NbOfOwners ? NbOfOwners : ""}
                                    className={includes(tempMandatory, "NbOfOwners") ? "AlertRed" : ""}
                                    onChange={this.handleTextChange("NbOfOwners")} />
                            </div>
                        </div>
                    </FormGroup>

                    <FormGroup check>
                        <div className={includes(tempMandatory, "Water") ? "AlertRed row" : "row"}>
                            <Label className="col-12 p-0 mt-2 mb-0">Water</Label>
                            <div className="col-4">
                                <Label >
                                    <Input type="radio" name="WaterArtesianWell" id="WaterArtesianWell"
                                        checked={WaterArtesianWell}
                                        onChange={this.handleRadioChange("WaterArtesianWell", ["WaterGovernmentalInstallation", "WaterCannula"])} />Artesian Well
                                </Label>
                            </div>
                            <div className="col-4">
                                <Label >
                                    <Input type="radio" name="WaterGovernmentalInstallation" id="WaterGovernmentalInstallation"
                                        checked={WaterGovernmentalInstallation}
                                        onChange={this.handleRadioChange("WaterGovernmentalInstallation", ["WaterArtesianWell", "WaterCannula"])} />Governmental Installation
                                </Label>
                            </div>
                            <div className="col-4">

                                <Label >
                                    <Input type="radio" name="WaterCannula" id="WaterCannula"
                                        checked={WaterCannula}
                                        onChange={this.handleRadioChange("WaterCannula", ["WaterArtesianWell", "WaterGovernmentalInstallation"])} />Cannula
                                </Label>
                            </div>
                        </div>
                    </FormGroup>

                    <FormGroup check>
                        <div className={includes(tempMandatory, "Well") ? "AlertRed row" : "row"}>
                            <Label className="col-12 p-0 mt-2 mb-0">Well</Label>
                            <div className="col-4">
                                <Label >
                                    <Input type="radio" name="WellAvailable" id="WellAvailable"
                                        checked={WellAvailable}
                                        onChange={this.handleRadioChange("WellAvailable", ["WellNotAvailable"])} />Available
                                </Label>
                            </div>
                            <div className="col-4">

                                <Label >
                                    <Input type="radio" name="WellNotAvailable" id="WellNotAvailable"
                                        checked={WellNotAvailable}
                                        onChange={this.handleRadioChange("WellNotAvailable", ["WellAvailable"])} />Not Available
                                </Label>
                            </div>
                        </div>

                    </FormGroup>

                    <FormGroup check>
                        <div className={includes(tempMandatory, "ConciergeHouse") ? "AlertRed row" : "row"}>
                            <Label className="col-12 p-0 mt-2 mb-0">Concierge</Label>
                            <div className="col-4">
                                <Label >
                                    <Input type="radio" name="ConciergeHouseAvailable" id="ConciergeHouseAvailable"
                                        checked={ConciergeHouseAvailable}
                                        onChange={this.handleRadioChange("ConciergeHouseAvailable", ["ConciergeHouseNotAvailable"])} />  House Available
                                </Label>
                            </div>

                            <div className="col-4">

                                <Label >
                                    <Input type="radio" name="ConciergeHouseNotAvailable" id="ConciergeHouseNotAvailable"
                                        checked={ConciergeHouseNotAvailable}
                                        onChange={this.handleRadioChange("ConciergeHouseNotAvailable", ["ConciergeHouseAvailable"])} />House Not Available
                                </Label>
                            </div>
                        </div>

                    </FormGroup>

                    <FormGroup check>
                        <div className={includes(tempMandatory, "WeatherConditions") ? "AlertRed row" : "row"}>
                            <Label className="col-12 p-0 mt-2 mb-0">Weather Condition</Label>
                            <div className="col-4">
                                <Label >
                                    <Input type="radio" name="WeatherConditionsWindy" id="WeatherConditionsWindy"
                                        checked={WeatherConditionsWindy}
                                        onChange={this.handleRadioChange(
                                            "WeatherConditionsWindy",
                                            ["WeatherConditionsSunny",
                                                "WeatherConditionsCold",
                                                "WeatherConditionsFreezing",
                                                "WeatherConditionsHot",
                                                "WeatherConditionsModerate"])} />Windy
                                </Label>
                            </div>
                            <div className="col-4">
                                <Label >
                                    <Input type="radio" name="WeatherConditionsSunny" id="WeatherConditionsSunny"
                                        checked={WeatherConditionsSunny}
                                        onChange={this.handleRadioChange(
                                            "WeatherConditionsSunny",
                                            ["WeatherConditionsWindy",
                                                "WeatherConditionsCold",
                                                "WeatherConditionsFreezing",
                                                "WeatherConditionsHot",
                                                "WeatherConditionsModerate"])} />Sunny
                                </Label>
                            </div>
                            <div className="col-4">
                                <Label >
                                    <Input type="radio" name="WeatherConditionsCold" id="WeatherConditionsCold"
                                        checked={WeatherConditionsCold}
                                        onChange={this.handleRadioChange(
                                            "WeatherConditionsCold",
                                            ["WeatherConditionsWindy",
                                                "WeatherConditionsSunny",
                                                "WeatherConditionsFreezing",
                                                "WeatherConditionsHot",
                                                "WeatherConditionsModerate"])} />Cold
                                </Label>
                            </div>
                            <div className="col-4">
                                <Label >
                                    <Input type="radio" name="WeatherConditionsFreezing" id="WeatherConditionsFreezing"
                                        checked={WeatherConditionsFreezing}
                                        onChange={this.handleRadioChange(
                                            "WeatherConditionsFreezing",
                                            ["WeatherConditionsWindy",
                                                "WeatherConditionsSunny",
                                                "WeatherConditionsCold",
                                                "WeatherConditionsHot",
                                                "WeatherConditionsModerate"])} />Freezing
                                </Label>
                            </div>
                            <div className="col-4">
                                <Label >
                                    <Input type="radio" name="WeatherConditionsHot" id="WeatherConditionsHot"
                                        checked={WeatherConditionsHot}
                                        onChange={this.handleRadioChange(
                                            "WeatherConditionsHot",
                                            ["WeatherConditionsWindy",
                                                "WeatherConditionsSunny",
                                                "WeatherConditionsCold",
                                                "WeatherConditionsFreezing",
                                                "WeatherConditionsModerate"])} />Hot
                                </Label>
                            </div>
                            <div className="col-4">
                                <Label >
                                    <Input type="radio" name="WeatherConditionsModerate" id="WeatherConditionsModerate"
                                        checked={WeatherConditionsModerate}
                                        onChange={this.handleRadioChange(
                                            "WeatherConditionsModerate",
                                            ["WeatherConditionsWindy",
                                                "WeatherConditionsSunny",
                                                "WeatherConditionsCold",
                                                "WeatherConditionsFreezing",
                                                "WeatherConditionsHot"])} />Moderate
                                </Label>
                            </div>
                        </div>

                    </FormGroup>

                    <FormGroup check>
                        <div className={includes(tempMandatory, "Relation") ? "AlertRed row" : "row"}>
                            <Label className="col-12 p-0 mt-2 mb-0">Relation</Label>
                            <div className="col-4">
                                <Label >
                                    <Input type="radio" name="RelationPartnerShip" id="RelationPartnerShip"
                                        checked={RelationPartnerShip}
                                        onChange={this.handleRadioChange("RelationPartnerShip", ["RelationRent"])} />PartnerShip
                                </Label>
                            </div>
                            <div className="col-4">

                                <Label >
                                    <Input type="radio" name="RelationRent" id="RelationRent"
                                        value={RelationRent}
                                        onChange={this.handleRadioChange("RelationRent", ["RelationPartnerShip"])} />Rent
                                </Label>
                            </div>
                        </div>

                    </FormGroup>

                    <FormGroup>
                        <Label className={includes(tempMandatory, "Message") ? "AlertRed" : ""}>Concerns</Label>
                        <Input type="textarea" name="Concerns" id="Concerns"
                            value={Concerns ? Concerns : ""}
                            onChange={this.handleTextChange("Concerns")} />
                    </FormGroup>
               
                </Form>
                <Button style={{ float: 'right' }} className="agri-background" onClick={this.handleSubmit}>Submit</Button>
            </>
        )
    }
}

export default NewLand;

