import React from 'react';
import Footer from './Footer';
import { map } from 'lodash';

class Agreements extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobileview: window.innerWidth < 768 ? true : false
    };

    this.dataArray = [
      {
        image: '/images/activities/GEO_7258.png',
        title: "Initial Project",
        location: "Kfarhata - koura",
        area: "10,000 m",
        cultivation: ["70.000 Thyme Plants"]
      },
      {
        image: '/images/activities/GEO_4404.png',
        title: "Btaaboura Project",
        location: "Btaaboura - koura",
        area: "7,000m",
        cultivation: ["70.000 Thyme Plants", "200 Summac Shrubs"]
      },
      {
        image: '/images/activities/GEO_4292.png',
        title: "Kelbata Project",
        location: "Kelbata - Koura ",
        area: "12,000m",
        cultivation: ["100.000 Thyme Plants"]
      },
      {
        image: '/images/activities/GEO_7292.png',
        title: "Mejdlaya Project ",
        location: "Mejdlaya - Zgharta",
        area: "20,000m",
        cultivation: ["4,000 Summac Shrubs"]
      },
    ]
  }

  render() {
    let { isMobileview } = this.state
    return (
      <div id="AgreementsMainContainer" className="container">

        <div className="MainImgAndTitleContainer">
          <div className="MainImgContainer" />
          <div className="TitleContainer">Activities</div>
        </div>
        <div id="AgreementsContainer">
          <div id="AgreementsTopContainer">
            <div className='row'>
              <div className='col-sm-12 col-md-6'>
                {!isMobileview &&
                  <img alt="" src='/images/activities/366.png' style={{ marginBottom: "18px", height: "208px", width: "100%", objectFit: "contain" }} />
                }
                <img alt="" src='/images/activities/367.png' style={{ height: "208px", width: "100%", objectFit: "contain" }} />
              </div>
              <div className='col-sm-12 col-md-6' style={{}}>
                <p style={{ fontSize: "24px", fontWeight: "bolder", color: "#04423C", textAlign: "left" }}>It’s Time for Thyme</p>
                <p style={{ fontSize: "18px", color: "#22B05B", textAlign: "left" }}><a style={{ color: "#22B05B" }} href='https://www.lb.undp.org/content/lebanon/en/home/stories/it_s-time-to-thyme0.html' target="_blank" rel="noopener noreferrer"> Source: UNDP Lebanon </a></p>
                <p style={{ fontSize: "18px", textAlign: "left" }}>UNDP, in partnership with Germany through Kfw, is supporting 2,312 farmers across 113 villages in Lebanon in leading the path towards a sustainable and green economic recovery through cash-for-work to stimulate local markets, especially in rural areas, creating a ripple effect of benefits for vulnerable people across Lebanon. This comprehensive intervention has generated 250.000 worker days so far for more than 6,000 women and men working in the fields and in agro-food production.</p>
              </div>
              <div className="col-12" style={{ textAlign: "-webkit-center" }}>
                {/* {!isMobileview &&
                <p style={{ fontSize: "24px", fontWeight: "bolder", color: "#04423C", margin: "80px 15px 20px  15px", maxWidth: "850px" }}>Agricultura is working with a variety of businesses and organization while it emphasizes the delivery of high quality raw material and products</p>
                } */}
                {/* {isMobileview && */}
                <p style={{ fontSize: "24px", fontWeight: "bolder", color: "#04423C", margin: "80px 15px 20px  15px", maxWidth: "850px" }}>Client Portfolio</p>
                {/* } */}
              </div>
            </div>
          </div>

          <div className='row' style={{ textAlign: "-webkit-center", marginBottom: "80px", marginTop: "50px" }}>
            <div className='col-6 col-xs-6 col-sm-6 col-md-3'>
              <img alt="" src='/images/activities/bazc.png' style={{ height: "160px", width: "160px", objectFit: "contain" }} />
            </div>
            <div className='col-6 col-xs-6 col-sm-6 col-md-3'>
              <img alt="" src='/images/activities/Fakra.png' style={{ height: "160px", width: "160px", objectFit: "contain" }} />
            </div>
            <div className='col-6 col-xs-6 col-sm-6 col-md-3'>
              <img alt="" src='/images/activities/FFB.png' style={{ height: "160px", width: "160px", objectFit: "contain" }} />
            </div>
            <div className='col-6 col-xs-6 col-sm-6 col-md-3'>
              <img alt="" src='/images/activities/ka.png' style={{ height: "160px", width: "160px", objectFit: "contain" }} />
            </div>
          </div>

          <div id="AgreementsBodyContainer">
            {/* <div id="AgreementsIcons" className="row">
              <div className="col-6" >
                <img alt="" src='/images/partnership/RuralDelights.png' style={{ height: "120px", width: "120px", objectFit: "contain", float: "right" }} />
              </div>
              <div className="col-6">
                <img alt="" src='/images/partnership/UNDP.png' style={{ height: "120px", width: "120px", objectFit: "contain" }} />
              </div>
            </div> */}
            {/* <div id="AgreementsVideos" className="row">
            Agreements vodeos link
            </div> */}
            <div className="row">
              <div className="col-12" style={{ marginBottom: "100px" }}>
                <h3 style={{ fontSize: "38px", fontWeight: "bolder", color: "#04423C" }}>Projects</h3>
              </div>
          {!isMobileview &&
            <div className="col-12" style={{ textAlign: "-webkit-center", marginBottom: "100px" }}>
            <div style={{ fontSize: "24px", fontWeight: "bolder", color: "#22B05B", maxWidth: "850px" }}>Agricultura plus is monitoring 4 agricultural projects. these project were established within 1 year as the company is still expanding and workink on new projects</div>
          </div>
          }
            </div>


            <div className='row'>
              {
                map(this.dataArray, (eachElem, key) => {
                  return (
                    <div key={key} className='col-12 col-xs-12 col-lg-6'>
                      <div>
                        <img alt="" src={eachElem.image} style={{ height: "270px", width: "100%", objectFit: "contain" }} />
                      </div>
                      <div className='row'>
                        <div className="col-12" style={{ fontSize: "22px", color: "#04423C", fontWeight: "bolder" }}>
                          {eachElem.title}
                        </div>
                        {!isMobileview &&
                          <><div className="col-4" style={{ fontSize: "16px" }}>
                            {eachElem.location}
                          </div>
                            <div className="col-3" style={{ fontSize: "16px", whiteSpace: "nowrap" }}>
                              {eachElem.area}<sup>2</sup>
                            </div>
                            <div className="col-5" style={{ fontSize: "16px" }}>
                              {
                                map(eachElem.cultivation, (cultivationElem, key) => {
                                  return (
                                    <div key={key} style={key === 1 ? { whiteSpace: "nowrap" } : { whiteSpace: "nowrap" }}>{cultivationElem}</div>
                                  )
                                })

                              }
                            </div>
                          </>}
                        {isMobileview &&
                          <div className="col-4" style={{ fontSize: "16px", whiteSpace: "nowrap" }}>
                            {eachElem.location}
                          </div>}
                        <div className="col-12 col-md-4">
                          <p style={{ borderTop: "1px solid #23B05C", color: "#23B05C" }}>Location</p>
                        </div>
                        {isMobileview &&
                          <div className="col-3" style={{ fontSize: "16px", whiteSpace: "nowrap" }}>
                            {eachElem.area}<sup>2</sup>
                          </div>}
                        <div className="col-12 col-md-3">
                          <p style={{ borderTop: "1px solid #23B05C", color: "#23B05C" }}>Area</p>
                        </div>
                        {isMobileview &&
                          <div className="col-5" style={{ fontSize: "16px" }}>
                            {
                              map(eachElem.cultivation, (cultivationElem, key) => {
                                return (
                                  <div key={key} style={key === 1 ? { whiteSpace: "nowrap" } : { whiteSpace: "nowrap" }}>{cultivationElem}</div>
                                )
                              })
                            }
                          </div>}
                        <div className="col-12 col-md-5">
                          <p style={{ borderTop: "1px solid #23B05C", color: "#23B05C" }}>Cultivation</p>
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </div>

            <div className="row" >
              <h3 style={{ fontSize: "34px", color: "#04423C", padding: "30px", fontWeight: "bolder", margin: "50px 0", border: "1px solid rgb(34, 176, 91)" }}>$50,000 Invested / 50.000 m<sup>2</sup></h3>
            </div>

            <div className="row" >
              <h3 style={{ fontSize: "38px", color: "#04423C", fontWeight: "bolder", margin: "50px 0" }}>Low Risk Opportunities</h3>
            </div>
            <div style={{ maxWidth: "1200px", margin: "auto" }}>
              <div className="row">

                <div className="col-sm-12 col-md-3" style={{ fontSize: "89px", color: "#22B05B", fontWeight: "bolder", paddingLeft: "40px" }}>
                  01
                </div>
                <div className="col-sm-12 col-md-9">
                  <h3 style={{ fontSize: "38px", color: "#04423C", fontWeight: "bolder", paddingTop: "30px" }}>uncontrollable agricultural events</h3>
                </div>

                <div className="col-sm-12 col-md-3" style={{ padding: "50px 50px" }}>
                  <img alt="" src='/images/activities/856.png' style={{ height: "120px", width: "120px", objectFit: "contain" }} />
                </div>
                <div className="col-sm-12 col-md-9">
                  <h5 style={{ fontSize: "33px", color: "#04423C", fontWeight: "bolder" }}>Tracking records</h5>
                  <div style={{ fontSize: "25px" }}>
                    Agricultura Plus tackles this type of risks by shedding lights on its successful tracking records. Such records will help the company understand the practical benefits of our offer and to observe its benefits with other businesses
                  </div>
                </div>

                <div className="col-sm-12 col-md-3" style={{ padding: "50px 26px" }}>
                  <img alt="" src='/images/activities/862.png' style={{ height: "140px", width: "140px", objectFit: "contain" }} />
                </div>
                <div className="col-sm-12 col-md-9" style={{ marginTop: "30px" }}>
                  <h5 style={{ fontSize: "33px", color: "#04423C", fontWeight: "bolder" }}>Short term ROI</h5>
                  <div style={{ fontSize: "25px" }}>
                    In other words the company reduces the risks by targeting projects that have fast and high ROI potential. This type of projects will reduce the risks and create a fruitful relationship from the very first years or even months of a partnership
                  </div>
                </div>

                <div className="col-sm-12 col-md-3" style={{ padding: "50px 50px" }}>
                  <img alt="" src='/images/activities/860.png' style={{ height: "100px", width: "100px", objectFit: "contain" }} />
                </div>
                <div className="col-sm-12 col-md-9" style={{ marginTop: "30px", marginBottom: "100px" }}>
                  <h5 style={{ fontSize: "33px", color: "#04423C", fontWeight: "bolder" }}>Type of crops</h5>
                  <div style={{ fontSize: "25px" }}>
                    Agricultura Plus target low risk types of crops. Crops like thyme and sumac are prone to minim diseases and require much less attention than other types of crops hence the low risk.
                  </div>
                </div>

                <div className="col-sm-12 col-md-3" style={{ fontSize: "89px", color: "#22B05B", fontWeight: "bolder", paddingLeft: "40px" }}>
                  02
                </div>
                <div className="col-sm-12 col-md-9" >
                  <h3 style={{ fontSize: "38px", color: "#04423C", fontWeight: "bolder", paddingTop: "30px" }} >Implementation and managerial responsibilities</h3>
                </div>
                <div className="col-sm-12 col-md-3" style={{ padding: "0px 50px" }}>
                  <img alt="" src='/images/activities/858.png' style={{ height: "100px", width: "100px", objectFit: "contain" }} />
                </div>
                <div className="col-sm-12 col-md-9" style={{ fontSize: "25px" }} >
                  By being a partner in the project, Agricultura Plus is carrying the risk just like the company investing. This type of operation reduces the conflict of interest and guarantee high productivity and efficiency for the company investing.
                </div>
              </div>

            </div>

            <div className="row">
              <div className="col-12">
                <h3 style={{ fontSize: "38px", fontWeight: "bolder", color: "#04423C", margin: "70px 0" }}>Partnerships</h3>
              </div>
              {!isMobileview &&
                <div className="agri-color" style={{ fontSize: "24px", fontWeight: "bold", maxWidth: "850px", textAlign: "center", margin: "auto" }} >Agricultura is working with a variety of businesses and organizations to ensure the delivery of high quality products and the sustainability of the enviroment and the society</div>
              }

            </div>

            <div className='row' style={{ textAlign: "-webkit-center" }}>
              {!isMobileview &&
                <div className='col-1' />
              }
              <div className='col-6 col-xs-6 col-sm-4 col-lg-2'>
                <img alt="" src='/images/activities/rural.png' style={{ height: "160px", width: "100%", objectFit: "contain" }} />
              </div>
              <div className='col-6 col-xs-6 col-sm-4 col-lg-2'>
                <img alt="" src='/images/activities/peace.png' style={{ height: "160px", width: "100%", objectFit: "contain" }} />
              </div>
              <div className='col-6 col-xs-6 col-sm-4 col-lg-2'>
                <img alt="" src='/images/activities/undp.png' style={{ height: "160px", width: "100%", objectFit: "contain" }} />
              </div>
              <div className='col-6 col-xs-6 col-sm-4 col-lg-2'>
                <img alt="" src='/images/activities/kfw.png' style={{ height: "160px", width: "100%", objectFit: "contain" }} />
              </div>
              <div className='col-6 col-xs-6 col-sm-4 col-lg-2'>
                <img alt="" src='/images/activities/rbml.png' style={{ height: "180px", width: "100%", objectFit: "contain" }} />
              </div>
              {!isMobileview &&
                <div className='col-1' />
              }
            </div>

            <div className="row" >
              <iframe width="630" height="402"
                style={{ margin: "auto" }}
                src="https://www.youtube.com/embed/IogrB0ImofE">
              </iframe>
            </div>
          </div>

        </div>

        <Footer />
      </div>
    )
  }

}

export default Agreements;

