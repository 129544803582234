import React from 'react';
import Footer from './Footer';
import BigProgressBar from '../BigComponents/bigMilesStone/BigMilesStone';

class OurStory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      windowWidth: window.innerWidth,
      isMobileview: window.innerWidth < 768 ? true : false
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.addEventListener("resize", this.handleResize);
  }

  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth });
  };

  render() {
    let { isMobileview } = this.state
    return (
      <div id="OurStoryMainContainer" className="container" >
        <div className="MainImgAndTitleContainer">
          <div className="MainImgContainer" />
          <div className="TitleContainer">Our Story</div>
        </div>
        <div id="OurStoryContainer">
          <div id="OurStoryTopContainer" >
            <div className="row" style={{ textAlign: "left" }}>
              <div className="col-sm-12 col-xs-12 col-lg-6">
                {!isMobileview &&
                  <img alt="" src='/images/ourstory/GEO_7310.png' style={{ marginBottom: "15px", height: "281px", width: "100%", objectFit: "contain" }} />
                }
                <img alt="" src='/images/ourstory/GEO_7614.png' style={{ height: "281px", width: "100%", objectFit: "contain" }} />
              </div>
              <div className="col-sm-12 col-xs-12 col-lg-6" style={{ fontSize: "18px", padding: "0 40px 0 40px" }}>
                <div style={{ paddingTop: "30px" }}>In 2020, just when the Lebanese economy started falling apart, a group of young Lebanese graduates decided not to leave their country and instead make a shift in their mindset that would be beneficial for both, the country and themselves. Coming from different backgrounds, Rabih and Rami founded Agricultura Plus, an agricultural company with a unique and innovative concept.</div>
                <br></br>
                <div> Agricultura Plus has become for them and for others a symbol of resistance and sustainability.</div>
                <br></br>
                <div>With its model, Agricultura Plus is helping businesses overcome the nationwide struggle in terms of supply and raw material. The model will assist the businesses in ensuring sustainability and stability in terms of operations and supply.</div>
                <br></br>
                <div>Today, Agricultura Plus has been able to attract several investors, has created its own brand of thyme, and is operating in 4 lands with a total area of 50,000m<sup>2</sup> of cultivated fields.</div>
              </div>
            </div>
          </div>
          <div id="OurStoryBodyContainer">
            <div className="row" style={{ margin: "50px 0 0 0" }}>
              <div className="col-12" style={{ margin: "100px 0" }}>
                <BigProgressBar />
              </div>
            </div>
            <div className="row">
              {this.state.windowWidth < 992 &&
                <div className="col-sm-12 col-xs-12 col-lg-6">
                  <img alt="" src='/images/ourstory/GEO_8091.png' style={{ height: "379px", width: "100%", objectFit: "cover" }} />
                </div>
              }
              <div className="col-sm-12 col-xs-12 col-lg-6" style={{ fontSize: "20px", padding: " 0 40px 0 40px", marginTop: isMobileview ? "30px" : "" }}>
                <h3 style={{ fontSize: "33.72px", fontWeight: "bold", color: "#04423C", paddingTop: "20px", textAlign: "left" }}>Our Impact </h3>
                Our corporate social responsibility relies on two pillars: the environment and social equity. Agricultura Plus has been working with several NGOs to reduce gender disparities in the agricultural sector. In late 2020, the company was engaged in several project with NGOs funded by the UNDP to reduce wage discrimination in the sector. In addition, the company follows an ecologically-correct policy, hence its advanced and sustainable methods for a better environment.
              </div>

              {this.state.windowWidth >= 992 &&
                <div className="col-sm-12 col-xs-12 col-lg-6">
                  <img alt="" src='/images/ourstory/GEO_8091.png' style={{ height: "379px", width: "100%", objectFit: "cover" }} />
                </div>
              }
            </div>
          </div>
          <Footer />
        </div>
      </div>
    )
  }

}

export default OurStory;