import React from 'react';
import ImgSlider from './ImgSlider';
import Footer from './Footer';
import BusinessApplication from '../forms/BusinessApplication';
import LandOwnerApplication from '../forms/LandOwnerApplication';
// import InvastorApplication from '../forms/InvastorApplication';
import {
  Button,
  Label,
  Input,
} from 'reactstrap';
import { map, cloneDeep, isEmpty, remove, includes } from "lodash"

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      windowWidth: window.innerWidth,
      isMobileview: window.innerWidth < 768 ? true : false,
      CoopType: "Zaatar",
      LandArea: "",
      NumberOfYears: "",

      AverageYearlyProduction: "",
      EstablishmentCostInDollar: "",
      AverageProfitYearlyInDollar: "",
      NumberOfSeasons: "",


      isOpen: false,

      mandatoryFields: [
        "CoopType",
        "LandArea",
        "NumberOfYears",
      ],

      tempMandatory: [],
      showErrorMessage: ''
    };
    this.coopTypeValues = {
      Zaatar:
      {
        numberOfPCoopInMeter: 6,
        productivityInMeterSuqare: 0.1,
        numberOfPSeasons: 2,
        costOfMeterSuqareInDollarsLessfive: 1.2,
        costOfMeterSuqareInDollarsfive: 0.95,
        costOfMeterSuqareInDollarsMorefive: 0.7,
        SellingPriceInDollars: 2.7,
      },
      Summac:
      {
        numberOfPCoopInMeter: 0.2,
        productivityInMeterSuqare: 2,
        numberOfPSeasons: 1,
        costOfMeterSuqareInDollarsLessfive: 1,
        costOfMeterSuqareInDollarsfive: 0.9,
        costOfMeterSuqareInDollarsMorefive: 0.8,
        SellingPriceInDollars: 3.5,
      },
      Mint:
      {
        numberOfPCoopInMeter: 4,
        productivityInMeterSuqare: 0.06,
        numberOfPSeasons: 2,
        costOfMeterSuqareInDollarsLessfive: 1.4,
        costOfMeterSuqareInDollarsfive: 1.2,
        costOfMeterSuqareInDollarsMorefive: 0.9,
        SellingPriceInDollars: 5,
      },
      Pepper:
      {
        numberOfPCoopInMeter: 3,
        productivityInMeterSuqare: 0.08,
        numberOfPSeasons: 2,
        costOfMeterSuqareInDollarsLessfive: 1.5,
        costOfMeterSuqareInDollarsfive: 1.3,
        costOfMeterSuqareInDollarsMorefive: 1.1,
        SellingPriceInDollars: 7.2,
      },
      Basil:
      {
        numberOfPCoopInMeter: 7,
        productivityInMeterSuqare: 0.04,
        numberOfPSeasons: 3,
        costOfMeterSuqareInDollarsLessfive: 1.5,
        costOfMeterSuqareInDollarsfive: 1.3,
        costOfMeterSuqareInDollarsMorefive: 1.1,
        SellingPriceInDollars: 12,
      },
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.addEventListener("resize", this.handleResize);
  }

  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth });
  };

  handleTextChange = val => event => {
    let tempMandatory = cloneDeep(this.state.tempMandatory)
    let { mandatoryFields } = this.state

    if (includes(mandatoryFields, val)) {
      tempMandatory = remove(tempMandatory, element => {
        return element !== val
      })
    }
    this.setState({ tempMandatory, [val]: event.target.value, showErrorMessage: '' })
  }

  handleSelectorChange = name => event => {
    let value = event.target.value
    this.setState({ [name]: value })
  }

  handleCalculate = () => {
    if (this.validateForm() === true) {
      let { LandArea, NumberOfYears } = this.state

      let CoopParam = this.coopTypeValues[this.state.CoopType]

      let EstablishmentCostInDollar
      let AverageYearlyProduction = Math.round(LandArea * CoopParam.numberOfPCoopInMeter * CoopParam.productivityInMeterSuqare * CoopParam.numberOfPSeasons * 10) / 10
      if (LandArea <= 5000)
        EstablishmentCostInDollar = Math.round(LandArea * CoopParam.costOfMeterSuqareInDollarsLessfive * 10) / 10
      else if (LandArea > 5000 && LandArea <= 10000)
        EstablishmentCostInDollar = Math.round(LandArea * CoopParam.costOfMeterSuqareInDollarsfive * 10) / 10
      else if (LandArea > 10000)
        EstablishmentCostInDollar = Math.round(LandArea * CoopParam.costOfMeterSuqareInDollarsMorefive * 10) / 10
      let AverageProfitYearlyInDollar = Math.round(((AverageYearlyProduction * CoopParam.SellingPriceInDollars) - (EstablishmentCostInDollar / NumberOfYears) + 1000) * 10) / 10
      let NumberOfSeasons = CoopParam.numberOfPSeasons

      this.setState({ AverageYearlyProduction, EstablishmentCostInDollar, AverageProfitYearlyInDollar, NumberOfSeasons })
    }
  }

  validateForm = () => {
    let tempMandatory = (this.state.tempMandatory)
    map(this.state.mandatoryFields, eachElem => {
      if (isEmpty(this.state[eachElem]) && !includes(tempMandatory, eachElem))
        tempMandatory.push(eachElem)
    })

    this.setState({ tempMandatory, showErrorMessage: !isEmpty(tempMandatory) ? 'Some fields are empty or invalid' : '' })



    setTimeout(() => {
      this.setState({ showErrorMessage: '' })
    }, 8000)
    if (!isEmpty(tempMandatory)) return false
    else if (this.state.CoopType === "Summac" && this.state.NumberOfYears < 7) {
      this.setState({ showErrorMessage: "Summacs requires at least a 7 years lifespan" })
      return false
    }
    else return true
  }

  toggleModal = val => event => {
    this.setState({
      isOpen: val === "open" ? true : false,
      CoopType: "Zaatar",
      LandArea: "",
      NumberOfYears: "",

      AverageYearlyProduction: "",
      EstablishmentCostInDollar: "",
      AverageProfitYearlyInDollar: "",
      NumberOfSeasons: "",

      mandatoryFields: [
        "CoopType",
        "LandArea",
        "NumberOfYears",
      ],


      tempMandatory: [],
      showErrorMessage: ''
    })
  }

  handleChangePath = (url) => event => {
    this.props.history.push(url);
  }

  render() {
    let {
      CoopType,
      LandArea,
      NumberOfYears,
      AverageYearlyProduction,
      EstablishmentCostInDollar,
      AverageProfitYearlyInDollar,
      NumberOfSeasons,
      tempMandatory,
      isMobileview, } = this.state
    return (
      <div id="HomeMainContainer" className="container" style={{ padding: "0" }}>
        <ImgSlider />

        <div id="secondPart">
          {/* <div className="col-sm-12 col-lg-6">
            <img src='/images/home/sec1img.jpg' style={{ height: "100%", width: "100%", objectFit: "cover" }} />
          </div>

          <div className="col-sm-12 col-lg-6"style={ReadMore ? {} :{ paddingTop: "70px" }} >
            <p> Agricultura Plus was founded by a group of aspiring Lebanese entrepreneurs that have the needed experience and knowledge to assist businesses achieve vertical integration and sustainability. The developed business model is innovative as it works to eliminate several pain points that a business might face when it comes to supply. This concept will help corporates establish independency and balance bargaining powers.</p>

            <Button style={{ backgroundColor: "#7fc000", marginLeft: "38%" }} onClick={this.toggleReadMore}>Read more</Button>
            <Fade in={ReadMore} className="mt-3" style={ReadMore ? {} :{ display: "none" } }><p>Agricultura Plus is specialized in establishing and monitoring agricultural projects covering several types of crops. To achieve ultimate efficiency, the company has defined a multiple set of tools to keep our clients involved throughout the experience. Before signing any agreement, Agricultura Plus presents a well-developed feasibility study with forecasts and a cost benefits analysis, through which our client can easily track the benefits of the vertical integration in terms of price and stability. Moreover, industrial, and food-processing companies struggle to find the right supplier that can supply consistent quality, quantity, and price, and this is one of the essential problems that Agricultura tries to tackle.</p>
              <p>The company has developed a dashboard enabling our users to track all activities on an assigned project. The traceability dashboard shows the pace of activities, a tacking record of production, and forecasts for the upcoming years. This dashboard will enhance the experience of our clients as they will be able to follow relevant parameters related to the supply of raw materials.</p>
              <p>Projects are monitored by certified agronomists and project managers as we will make sure to offer the best service throughout our journey. A project can be established and/or monitored by Agricultura based on the guidelines and the demands of our client.</p>

            </Fade>
          </div> */}
          <div className="row" style={{ marginBottom: "120px" }}>
            <div className="col-sm-12 col-lg-6">
              <img alt="" src='/images/home/GEO_7446.png' style={{ height: "325px", width: "100%", objectFit: "cover" }} />
            </div>
            <div className="col-sm-12 col-lg-6">
              <h1 style={{ color: "#04423C", paddingTop: "30px" }}>Landowners Opportunity</h1>
              <p>Landowners are able now to invest their lands in the agriculture sector. Agricultura Plus provides such opportunity by acquiring unused lands for business purposes. </p>
              <p>Apply now to become a part of Agricultura's cycle.</p>
              <LandOwnerApplication
                modalName="APPLY NOW!" />
            </div>
          </div>
          <div className="row" style={{ marginBottom: "120px" }}>
            {this.state.windowWidth < 992 &&
              <div className="col-sm-12 col-lg-6" style={{ marginTop: "50px" }}>
                <img alt="" src='/images/home/GEO_4380.png' style={{ height: "325px", width: "100%", objectFit: "cover" }} />
              </div>
            }
            <div className="col-sm-12 col-lg-6" style={{ paddingTop: "50px" }}>
              <h1 style={{ color: "#04423C" }}>Businesses Opportunity</h1>
              <p> Individuals, businesses and organizations are now able to outsource agriculture services with promising returns including low cost production, quality control consistency, and guaranteed quantities.</p>
              <div>
                <div style={{ float: "left", marginRight: "20px", marginBottom: "10px" }}>Apply now to benefit from Agricultura's services.</div>
              </div>
              <div>
                <div style={{ float: "left", marginRight: "20px", marginBottom: "10px" }}>
                  <BusinessApplication />
                </div>
                <div style={{ float: "left", marginRight: "20px", marginBottom: "10px" }}>
                  <LandOwnerApplication
                    modalName="INVESTOR APPLICATION" />
                </div>
              </div>
            </div>
            {this.state.windowWidth >= 992 &&
              <div className="col-sm-12 col-lg-6" style={{ marginTop: "50px" }}>
                <img alt="" src='/images/home/GEO_4380.png' style={{ height: "325px", width: "100%", objectFit: "cover" }} />
              </div>
            }

          </div>
        </div>

        <div id="MainCalculatorPart" style={{ marginBottom: "120px" }}>
          <div id="CalculatorPart">
            <div className="row">
              <div className="col-12">
                <h1 style={{ color: "#04423C" }}>Project Calculator</h1>
              </div>
              <div className="col-12 col-xs-12 col-md-3">
                <Label className={includes(tempMandatory, "CoopType") ? "AlertRed" : ""}>Coop Type</Label>
                <Input type="select"
                  name="CoopType"
                  id="CoopType"
                  value={CoopType}
                  onChange={this.handleSelectorChange("CoopType")}>
                  <option>Zaatar</option>
                  <option>Summac</option>
                  <option>Mint</option>
                  <option>Pepper</option>
                  <option>Basil</option>
                </Input>
              </div>
              <div className="col-12 col-xs-12 col-md-3">
                <Label className={includes(tempMandatory, "LandArea") ? "AlertRed" : ""}>Land Area (m<sup>2</sup>)</Label>
                <Input type="number" name="LandArea" id="LandArea"
                  value={LandArea ? LandArea : ""}
                  className={includes(tempMandatory, "LandArea") ? "AlertRed" : ""}
                  onChange={this.handleTextChange("LandArea")} />
              </div>
              <div className="col-12 col-xs-12 col-md-3">
                <Label className={includes(tempMandatory, "NumberOfYears") ? "AlertRed" : ""}>Number Of Years</Label>
                <Input type="number" name="NumberOfYears" id="NumberOfYears"
                  value={NumberOfYears ? NumberOfYears : ""}
                  className={includes(tempMandatory, "NumberOfYears") ? "AlertRed" : ""}
                  onChange={this.handleTextChange("NumberOfYears")} />
              </div>
              <div className="col-12 col-xs-12 col-md-3" style={{ paddingTop: "33px" }}>

                <Button className="agri-background" style={{ marginRight: "10px" }} onClick={this.handleCalculate}>Calculate</Button>
                <Button className="secondary" onClick={this.toggleModal("open")}>Clear</Button>
              </div>

              <br /> <br /><br /><br /><br />
              {EstablishmentCostInDollar &&
                <>
                  <div className="col-12">
                    <Label className={includes(tempMandatory, "AverageYearlyProduction") ? "AlertRed" : ""}>Average Yearly Production</Label>
                    <Label>: {AverageYearlyProduction ? AverageYearlyProduction : "0"}</Label>
                  </div>
                  <div className="col-12">
                    <Label className={includes(tempMandatory, "EstablishmentCostInDollar") ? "AlertRed" : ""}>Establishment Cost In USD</Label>
                    <Label>: {EstablishmentCostInDollar ? EstablishmentCostInDollar : "0"}</Label>
                  </div>
                  <div className="col-12">
                    <Label className={includes(tempMandatory, "AverageProfitYearlyInDollar") ? "AlertRed" : ""}>Average Yearly Profit In USD</Label>
                    <Label>: {AverageProfitYearlyInDollar ? AverageProfitYearlyInDollar : "0"}</Label>
                  </div>
                  <div className="col-12">
                    <Label className={includes(tempMandatory, "NumberOfSeasons") ? "AlertRed" : ""}>Number Of Seasons </Label>
                    <Label>: {NumberOfSeasons ? NumberOfSeasons : "0"}</Label>
                  </div>
                </>
              }
            </div>
          </div>
        </div>

        <div id="thirdPart" className="row" style={{ marginBottom: "120px" }}>
          <div className="" style={{ maxWidth: "500px" }}>
            <h1 style={{ color: "#04423C" }}> Highly valuing the welfare of society & the environment!</h1>
          </div>
        </div>

        <div id="fourthPart" style={{ textAlign: "left" }}>

          <div className="row" style={{ marginBottom: "120px" }}>
            <div className="col-sm-12 col-lg-6">
              <img alt="" src='/images/home/GEO_4411.png' style={{ height: "325px", width: "100%", objectFit: "cover" }} />
            </div>
            <div className="col-sm-12 col-lg-6">
              <h1 style={{ color: "#04423C", paddingTop: "10px" }}>Our Services</h1>
              <p>Agricultura Plus has developed an innovative business model that will help businesses overcome several pain points and challenges linked to the supply of raw materials</p>
              <div onClick={this.handleChangePath("/Services")} className='arrowMainContainer'>
                <div className='arrowContainer'>
                  <div className='arrowLine'></div>
                  <div className='arrowArrow' >&#62;</div>
                </div>
                <div className='arrowText'>DISCOVER OUR SERVICES</div>
              </div>
            </div>
          </div>

          <div className="row" style={{ marginBottom: "120px" }}>
            {isMobileview &&
              <div className="col-sm-12 col-lg-6" style={{marginBottom:"10px"}}>
                <img alt="" src='/images/home/GEO_7730.png' style={{ height: "325px", width: "100%", objectFit: "cover" }} />
              </div>
            }
            <div className="col-sm-12 col-lg-6">
              <h1 style={{ color: "#04423C" }}>Our Story</h1>
              <p>Agricultura Plus is a story of resistance where a young group of entrepreneurs have decided to fight the imposed economical reality by holding onto the roots of their homeland.</p>
              <div onClick={this.handleChangePath("/OurStory")} className='arrowMainContainer'>
                <div className='arrowContainer' style={{ marginBottom: "30px" }}>
                  <div className='arrowLine'></div>
                  <div className='arrowArrow' >&#62;</div>
                </div>
                <div className='arrowText' style={{ color: "#04423C" }}>DISCOVER OUR STORY</div>
              </div>
            </div>
            {!isMobileview &&
              <div className="col-sm-12 col-lg-6">
                <img alt="" src='/images/home/GEO_7730.png' style={{ height: "325px", width: "100%", objectFit: "cover" }} />
              </div>
            }
          </div>

          <div className="row" style={{ marginBottom: "120px" }}>
            <div className="col-sm-12 col-lg-6">
              <img alt="" src='/images/home/GEO_8251.png' style={{ height: "325px", width: "100%", objectFit: "cover" }} />
            </div>
            <div className="col-sm-12 col-lg-6">
              <h1 style={{ color: "#04423C", paddingTop: "10px" }}>Partnerships</h1>
              <p>To enhance productivity within its sustainable model, Agricultura Plus has been able to establish several partnerships and agreements</p>
              <div onClick={this.handleChangePath("/Agreements")} className='arrowMainContainer'>
                <div className='arrowContainer'>
                  <div className='arrowLine'></div>
                  <div className='arrowArrow'>&#62;</div>
                </div>
                <div className='arrowText'>DISCOVER OUR PARTNERSHIPS</div>
              </div>
            </div>
          </div>

          <div className="row" style={{ marginBottom: "120px" }}>
            {isMobileview &&
              <div className="col-sm-12 col-lg-6"style={{marginBottom:"10px"}}>
                <img alt="" src='/images/home/GEO_8385.png' style={{ height: "325px", width: "100%", objectFit: "cover" }} />
              </div>
            }
            <div className="col-sm-12 col-lg-6">
              <h1 style={{ color: "#04423C" }}>Professional Team</h1>
              <p>The Agricultura Plus team is well experienced and has a successful tracking record. Leaders and agritechs are joining forces together to guarantee supreme services and outcomes</p>
              <div onClick={this.handleChangePath("/About")} className='arrowMainContainer'>
                <div className='arrowContainer' style={{ marginBottom: "30px" }}>
                  <div className='arrowLine'></div>
                  <div className='arrowArrow' >&#62;</div>
                </div>
                <div className='arrowText'>DISCOVER OUR TEAM</div>
              </div>
            </div>
            {!isMobileview &&
              <div className="col-sm-12 col-lg-6">
                <img alt="" src='/images/home/GEO_8385.png' style={{ height: "325px", width: "100%", objectFit: "cover" }} />
              </div>
            }
          </div>
        </div>
        <Footer />
        {/* <div style={{ backgroundColor: "#00423c" }}>
          <div className="row">
            <div className="col-12">
              MEET OUR TEAM
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-lg-6">
              Rami Saba
              R&D Manager
              Agricultura Plus is establishing with several NGO's to make sure that the company is following operational sustainability and to help near communities grow.
            </div>

            <div className="col-sm-12 col-lg-6">
              Rabih Nefaa
              Operation Manager
              Our professional team is ready to transform your company's Project into a beneficial agreement that will work efficiently in terms of profitability and consistency.
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12 col-lg-6">
              Jad Njeim
              Sales Manager
              Businesses and organizations are now able to outsource agriculture services in order to guarantee low cost supply, quality control consistency, and guaranteed availability.
            </div>

            <div className="col-sm-12 col-lg-6">
              Gabriel Ghazal
              Agriculture Consultant
              Agricultura Plus is establishing partnerships with several NGO's to make sure that the company is following operational sustainability and to help near communities grow.
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12 col-lg-6">
            OUR PARTNERSHIPS
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum bbcwcbbcsbzjkzbb szbvvzzvzvbciudzn hs si hbzbzjzjzjjz
          </div>
          <div className="col-sm-12 col-lg-6">
            <img src='/images/home/sec3img.jpg' />
          </div>
        </div>

        <div className="row">
          <div>
            “We worked on multiple means to mitigate all Project risks.”
            Learn more about our
            Risk Mitigation
          </div>
          <div className="col-12">
            <img src='/images/home/sec4backg.jpg' width="100%" height="auto" />
          </div>
        </div> */}

        {/* <div className="row">
          <div className="col-12">
            OUR SUCCESS STORIES
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <img src='/images/home/ac_stories.jpg' width="100%" height="auto" />
          </div>
        </div> */}
      </div>
    )
  }

}

export default Home;