import React from 'react';
import { map, cloneDeep } from "lodash"

import "./bigMilesStone.css"

class BigMilesStone extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      BigMilesStoneData: [
        {
          id: 1,
          value: 'AgricultruaPlusFirstproject',
          label: 'Agricultrua Plus first project',
          title: 'Agricultrua Plus first project',
          textBody: 'Agricultura Plus established its first project in Spring 2020. 5,000 meter square of thyme were managed by the company’s agritechs and project managers. This project, located in Kfarhata – North Lebanon, helped the company acquire the needed experience.  Around 30,000 seedlings of thyme are monitored within this project helping the company extract the critical data and information related to this type of plant.',
          imgSrc: '/images/milesstone/Img1.jpeg',
          iconSrc: '/images/milesstone/Initiation.png',
          isSelected: true
        },
        {
          id: 2,
          value: 'RuraldeLightPartnership',
          label: 'Rural delight partnership',
          title: 'Rural delight partnership',
          textBody: 'A partnership with rural delight was established as we worked on a project funded by the UNDP and the German Bank for Development. This partnership is part of the company’s CSR strategic positioning, ensuring social and food sustainability.  Our experience with Rural Delight Cooperative was exceptional. Not only did the organization help financially in terms of operations, but they were also ready to provide marketing support.',
          imgSrc: '/images/milesstone/Ruraldelightpartnership.jpeg',
          iconSrc: '/images/milesstone/Partnership.png',
          isSelected: false
        },
        {
          id: 3,
          value: 'UNDPinterview',
          label: 'UNDP interview',
          title: 'UNDP interview',
          textBody: 'Agricultura Plus’ Co-founder Rami Saba was interviewed by the UNDP shedding light on our mission and vision.  The interview enhanced brand awareness among potential clients and reflected the true motifs behind the establishment of the business.',
          aTag: 'https://www.lb.undp.org/content/lebanon/en/home/stories/it_s-time-to-thyme0.html',
          imgSrc: '/images/milesstone/undp.jpeg',
          iconSrc: '/images/milesstone/Interview.png',
          isSelected: false
        },
        {
          id: 4,
          value: 'SecondProject',
          label: 'Second Project',
          title: 'Second Project',
          textBody: 'After acquiring the needed experience from its first project, Agricultura Plus decided to launch another project and expanded its operations in Fall 2020. Within this project, an additional 5,000 meter square of thyme seedlings became monitored by the company. As a result, the project enhanced the testing processes as more data was collected from thyme crops. Additionally, this project included planting the first batch of 200 sumac shrubs and thus testing new crops.',
          italicText: ' The project was funded by local investors',
          imgSrc: '/images/milesstone/sec2img.jpeg',
          iconSrc: '/images/milesstone/SecondProject.png',
          isSelected: false
        },
        {
          id: 5,
          value: 'ThirdProject',
          label: '3rd Project',
          title: '3rd Project',
          textBody: 'By spring 2021, Agricultura plus was launching its 3rd project. The project is located in Kelbata – North Lebanon with an area of 10,000 meter square. Agricultura Plus expanded its operations based on the market size and needs as the team became more proficient in agricultural management. ',
          imgSrc: '/images/milesstone/tproject.jpeg',
          iconSrc: '/images/milesstone/ThirdProject.png',
          isSelected: false
        },
        {
          id: 6,
          value: 'agrytechPhase1',
          label: 'agrytech Phase1',
          title: 'agrytech Phase1',
          textBody: 'Out of 400 startups agricultura plus was chosen amongst the 30 most qualified teams for the “agrytech” accelerator program. The first phase of this program helped the team develop several skills and define the market’s pain points and potential solutions',
          imgSrc: '/images/milesstone/agrytech.jpeg',
          iconSrc: '/images/milesstone/agrytechPhase1.png',
          isSelected: false
        },
        {
          id: 7,
          value: 'LaunchingOfTheBrand',
          label: 'Brand launching',
          title: 'Brand launching',
          textBody: 'After conducting the needed market studies, Agricultura Plus launched its brand “Time to Thyme.”  The product is differentiated on many levels. The tastes are unique, the quality is premium, and the packaging is influenced by the eco-friendly strategic positioning of the company.',
          imgSrc: '/images/milesstone/agryBrandLaunching.jpeg',
          iconSrc: '/images/milesstone/BrandLaunching.png',
          isSelected: false
        },
        {
          id: 8,
          value: 'AdvancingtoPhase2agrytech',
          label: 'Advancing to phase 2 agrytech',
          title: 'Advancing to phase 2 agrytech',
          textBody: 'Out of 30 startups, Agricultura Plus was chosen amongst the 15 most qualified teams for the Phase 2 in agrytech’s accelerator program. Throughout its journey with agrytech, Agricultura Plus developed its managerial business model. This model assists businesses in achieving vertical integration and eliminates problems in the supply chain.',
          imgSrc: '/images/milesstone/agrytech.jpeg',
          iconSrc: '/images/milesstone/agrytechPhase2.png',
          isSelected: false
        },
        {
          id: 9,
          value: 'PartnershipwithRBMLfoodLab',
          label: 'Partnership with RBML food Lab',
          title: 'Partnership with RBML food Lab',
          textBody: 'A partnership was signed with RBML food Lab. This partnership ensures the delivery of high-quality products and continuous testing. Agricultura plus emphasizes quality control and international standards. ',
          imgSrc: '/images/milesstone/rbml.jpeg',
          iconSrc: '/images/milesstone/RBMLPartnership.png',
          isSelected: false
        },
      ],
      numberOfIconsToDisplay: 0,
      milesStoneLineWidthToDisplay: "",
      dotContainer: "",
      milesStoneWidth: "",
      milesStonefullWidth: "",
      lineWidth: "",
      selected: 1,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowResize);
    this.handleWindowResize()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize);
  }

  handleWindowResize = () => {

    let windowWidth = document.getElementById("BigMilesStoneMainContainer").clientWidth - 60
    let milesStoneLineWidthToDisplay = windowWidth - 60
    let numberOfIconsToDisplay = 0
    let milesStonefullWidth, dotContainer, lineWidth

    if (windowWidth > 1700 && windowWidth <= 1900)
      numberOfIconsToDisplay = 9
    else if (windowWidth > 1500 && windowWidth <= 1700)
      numberOfIconsToDisplay = 8
    else if (windowWidth > 1300 && windowWidth <= 1500)
      numberOfIconsToDisplay = 7
    else if (windowWidth > 1100 && windowWidth <= 1300)
      numberOfIconsToDisplay = 6
    else if (windowWidth > 900 && windowWidth <= 1100)
      numberOfIconsToDisplay = 5
    else if (windowWidth > 700 && windowWidth <= 900)
      numberOfIconsToDisplay = 4
    else if (windowWidth > 500 && windowWidth <= 700)
      numberOfIconsToDisplay = 3
    else if (windowWidth > 300 && windowWidth <= 500)
      numberOfIconsToDisplay = 2
    else if (windowWidth <= 300)
      numberOfIconsToDisplay = 1
    dotContainer = milesStoneLineWidthToDisplay / numberOfIconsToDisplay
    lineWidth = (dotContainer - 40) / 2
    milesStonefullWidth = (this.state.BigMilesStoneData.length * 40) + ((lineWidth * 2) * this.state.BigMilesStoneData.length)

    this.setState({
      numberOfIconsToDisplay: numberOfIconsToDisplay,
      milesStoneLineWidthToDisplay: milesStoneLineWidthToDisplay,
      dotContainer: dotContainer,
      milesStonefullWidth: milesStonefullWidth,
      lineWidth: lineWidth,
    })


  }

  BigMilesStoneMainContant = (id, more, less) => e => {
    if (id !== null) {
      if (id > this.state.selected || more)
        document.getElementById("dotMainContainer").scrollLeft += this.state.dotContainer;
      else if (id < this.state.selected || less)
        document.getElementById("dotMainContainer").scrollLeft -= this.state.dotContainer;

      let BigMilesStoneData = cloneDeep(this.state.BigMilesStoneData)
      let selected
      map(BigMilesStoneData, (eachElem, key) => {
        if (eachElem.id === id) {
          eachElem.isSelected = true
          selected = eachElem.id
        }
        else
          eachElem.isSelected = false
      })
      this.setState({ BigMilesStoneData, selected })
    }
  }

  render() {

    let {
      selected,
      BigMilesStoneData,
      milesStoneLineWidthToDisplay,
      lineWidth,
      dotContainer,
      milesStonefullWidth
    } = this.state
    return (
      <div id="BigMilesStoneMainContainer" className="container" >
        <div className="squar" onClick={this.BigMilesStoneMainContant(this.state.selected !== 1 ? this.state.selected - 1 : null, false, true)}>
          <img alt="" src='/images/milesstone/Previous.svg' className="imgMilNextFor"></img>

        </div>
        <div id="dotMainContainer" style={{ width: milesStoneLineWidthToDisplay + "px" }}>
          <div id="dotBodyContainer" style={{ width: milesStonefullWidth + "px", float: "left" }}>
            {
              map(BigMilesStoneData, (eachElem, key) => {
                return (
                    <div className="dotContainer"
                      key={key}
                      onClick={this.BigMilesStoneMainContant(eachElem.id)}
                      style={{ width: dotContainer + "px" }}>
                      <div className="line" style={{ width: lineWidth + "px" }}></div>
                      <div id={eachElem.value} className={eachElem.isSelected ? "dot selectedDot" : "dot"} >
                        <img alt="" src={eachElem.iconSrc} className={eachElem.isSelected ? "selectedImg imgMilIcon" : "imgMilIcon greyFilter"}></img>
                      </div>
                      <div className="line" style={{ width: lineWidth + "px" }}></div>
                      <div className={eachElem.isSelected ? "title selectedTitle" : "title"}>{eachElem.label}</div>
                    </div>
                )
              })
            }
          </div>
        </div>
        <div className="squar" onClick={this.BigMilesStoneMainContant(this.state.selected !== 9 ? this.state.selected + 1 : null, true, false)}>
          <img alt="" src='/images/milesstone/Next.svg' className="imgMilNextFor" style={{ marginLeft: "3px" }}></img>
        </div>
        <div style={{ clear: "both" }} />
        <div id="BigMilesStoneMainContantContainer" >
          {map(BigMilesStoneData, (eachElem, key) => {
            if (eachElem.id === selected) {
              return (
                <div className="row " key={key}>
                  <div className="col-sm-12 col-xs-12 col-lg-4" id="BigMilesStoneMainContantContainerImg">
                    <div>
                      <img alt="" src={eachElem.imgSrc}  style={{ height: "300px", width: "100%", objectFit: "contain" }}></img>
                    </div>
                  </div>
                  <div className="col-sm-12 col-xs-12 col-lg-8" id="BigMilesStoneMainContantContainerText">
                    <div > <h3 style={{fontSize:"33.72px", fontWeight:"bold", color:"#04423C", textAlign:"left"}}>{eachElem.title}</h3>
                      {eachElem.aTag &&
                        <a href={eachElem.aTag} target="_blank" rel="noreferrer">{eachElem.aTag}</a>
                      }
                      <div style={{textAlign:"left" , fontSize:"20px", color:"#000"}}>{eachElem.textBody}</div>
                      {eachElem.italicText &&
                        <div style={{ fontStyle: "italic" }} >{eachElem.italicText}</div>
                      }
                    </div>
                  </div>
                </div>
              )
            }
          })
          }

        </div>
      </div>
    )
  }

}

export default BigMilesStone;

