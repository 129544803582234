import React from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import Footer from './Footer';

class Services extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openReadMoreModal: false,
      isMobileview: window.innerWidth < 768 ? true : false
    };
  }

  componentDidMount() {
    document.addEventListener("keydown", this.escFunction, false);
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
  }

  escFunction = (event) => {
    if (event.key === "Escape") {
      this.setState({
        openReadMoreModal: false
      })
    }
  }

  openReadMoreModal = () => {
    this.setState({ openReadMoreModal: !this.state.openReadMoreModal })
  }

  render() {
    let {isMobileview} =this.state
    return (
      <div id="ServicesMainContainer" className="container" >

        <div className="MainImgAndTitleContainer">
          <div className="MainImgContainer" />
          <div className="TitleContainer">Services</div>
        </div>

        <div id="ServicesContainer">

          <div id="ServicesTopContainer">
            <h3 style={{ fontSize:isMobileview? "25px":"35px", fontWeight: "bold" }}>OUR NETWORK IS GROWING</h3>
            <h1 style={{ fontSize:isMobileview? "40px": "86px", fontWeight: "bold" }} className="agri-color" >50,000m<sup>2</sup></h1>
            <h5 style={{ fontSize: "25px", fontWeight: "bold" }}>OF MANAGED PLOTS</h5>
            <div style={{ marginTop: "30px" }} className="row">

              <div className="col-12 col-xs-12 col-sm-6 col-md-3" style={{ marginTop:isMobileview? "15px": ""}}>
                <h1 className="agri-color" style={{ fontSize:isMobileview? "40px": "65px", fontWeight: "bold" }}>4</h1>
                <h5 style={{ fontSize:isMobileview? "15px": "19px", color: "#707070" }}> F&B companies agreements</h5>
              </div>
              
              <div className="col-12 col-xs-12 col-sm-6 col-md-3" style={{ marginTop:isMobileview? "20px": ""}}>
                <h1 className="agri-color" style={{ fontSize:isMobileview? "40px":  "65px", fontWeight: "bold" }}>5</h1>
                <h5 style={{ fontSize:isMobileview? "15px": "19px", color: "#707070" }}>different types of plants</h5>
              </div>

              <div className="col-12 col-xs-12 col-sm-6 col-md-3" style={{ marginTop:isMobileview? "20px": ""}}>
                <h1 className="agri-color" style={{ fontSize:isMobileview? "40px":  "65px", fontWeight: "bold" }}>3</h1>
                <h5 style={{ fontSize:isMobileview? "15px":  "19px", color: "#707070" }}> Innovative technique</h5>
              </div>

              <div className="col-12 col-xs-12 col-sm-6 col-md-3" style={{ marginTop:isMobileview? "20px": ""}}>
                <h1 className="agri-color" style={{ fontSize:isMobileview? "40px":  "65px", fontWeight: "bold" }}>3</h1>
                <h5 style={{ fontSize:isMobileview? "15px":  "19px", color: "#707070" }}> investors on 4 different projects</h5>
              </div>
            </div>
          </div>
          <div id="ServicesBodyContainer">

            <div className="row" >
              <h3 style={{ fontSize: "33px", color: "#04423C", fontWeight: "bold", marginBottom: "80px" }}>WHAT WE OFFER</h3>
            </div>

            <div className='row' style={{ textAlign: "-webkit-center", marginBottom: "120px" }}>
              {/* <div className='col-12c ol-xs-12 col-sm-4 col-lg-2'>
                <img alt="" src='/images/operations/827.png' style={{ height: "33px", width: "33px", objectFit: "contain" }} />
                <p>Traceability Dashboard</p>
              </div> */}
              <div className='col-6 col-xs-6 col-sm-4 col-lg-2 offset-lg-1'>
                <img alt="" src='/images/operations/826.png' style={{ height: "33px", width: "33px", objectFit: "contain" }} />
                <p>High Responsiveness</p>
              </div>
              <div className='col-6 col-xs-6 col-sm-4 col-lg-2'>
                <img alt="" src='/images/operations/822.png' style={{ height: "33px", width: "33px", objectFit: "contain" }} />
                <p>Supply Consistency</p>
              </div>
              <div className='col-6 col-xs-6 col-sm-4 col-lg-2' style={{ marginTop:isMobileview? "25px": ""}}>
                <img alt="" src='/images/operations/825.png' style={{ height: "33px", width: "33px", objectFit: "contain" }} />
                <p>Monthly Reports</p>
              </div>
              <div className='col-6 col-xs-6 col-sm-4 col-lg-2' style={{ marginTop:isMobileview? "25px": ""}}>
                <img alt="" src='/images/operations/824.png' style={{ height: "33px", width: "33px", objectFit: "contain" }} />
                <p>High ROI</p>
              </div>
              <div className='col-6 col-xs-6 col-sm-4 col-lg-2' style={{ marginTop:isMobileview? "25px": ""}}>
                <img alt="" src='/images/operations/823.png' style={{ height: "33px", width: "33px", objectFit: "contain" }} />
                <p>Quality Control</p>
              </div>
            </div>

            <div className="row" >
              <iframe width="630" height="402"
                style={{ margin: "auto" }}
                src="https://www.youtube.com/embed/eIsazTdtOQ8">
              </iframe>
            </div>

            <div className="row" style={{ marginBottom: "140px" }}>
              <div className="col-12 col-sm-12 col-lg-7 ">
                <img alt="" src='/images/home/GEO_8385.png' style={{ height: "360px", width: "100%", objectFit: "contain" }} />
              </div>
              <div className="col-12 col-sm-12 col-lg-5 ">
                <div>
                  <p style={{ fontSize: "33.72px", paddingTop: "15px", color: "#04423C" }}>Our Model</p>
                </div>
                <div style={{ fontSize: "20px", color: "#000" }}>
                  Agricultura Plus was founded by a group of aspiring Lebanese entrepreneurs who have the needed experience and knowledge to assist businesses achieve vertical integration and sustainability. The developed business model is innovative as it works to eliminate several pain points that a business might face when it comes to supply. This concept will help corporates establish independency and balance bargaining powers.
                </div>

                <div onClick={this.openReadMoreModal} style={{ float: "right", marginRight: "20%" }} className='arrowMainContainer'>
                  <div className='arrowContainer'>
                    <div className='arrowLine'></div>
                    <div className='arrowArrow' >&#62;</div>
                  </div>
                  <div className='arrowText'>READ MORE</div>
                </div>
                {/* <div>
                  Agricultura Plus is specialized in establishing and monitoring agricultural projects covering several types of crops. To achieve ultimate efficiency, the company has defined a multiple set of tools to keep clients involved throughout the experience. Before signing any agreement, Agricultura Plus presents a well-developed feasibility study with forecasts and a cost benefits analysis, through which clients can easily track the benefits of the vertical integration in terms of price and stability. Moreover, industrial and food-processing companies struggle to find the right supplier that can supply consistent quality, quantity, and price, which is another essential problem that Agricultura aims to tackle.
                </div>
                <div>
                  The company has developed a dashboard enabling the users to track all activities on an assigned project. The traceability dashboard shows the pace of activities, a tacking record of production, and forecasts for the upcoming years. This dashboard enhances the clients' experiences, as they would be able to follow relevant parameters related to the supply of raw materials.
                </div>
                <div>
                  Projects are monitored by certified agronomists and project managers in order to guarantee the best service throughout our journey. A project can be established and/or monitored by Agricultura Plus based on the guidelines and the demands of the client. </div> */}
              </div>
            </div>
            <div className="row" style={{ marginBottom: "30px" }}>
              <div className="col-xs-12  col-sm-3">
                <img alt="" src='/images/home/RawMaterialPrice.svg' style={{ height: "150px", width: "150px", objectFit: "cover" }} />
              </div>
              <div className="col-xs-12  col-sm-9" style={{ fontSize: "20px", color: "#000" }} >
                <h5 style={{ fontSize: "33.72px", color: "#04423C" }}>Consistent raw material price</h5>
                the price is pre-defined based on a well-established feasibility study. A cost benefit analysis is provided to ensure the long-term efficiency of an agreement with agricultura Plus</div>
            </div>
            <hr></hr>
            <div className="row" style={{ marginBottom: "30px" }}>
              <div className="col-xs-12  col-sm-3">
                <img alt="" src='/images/home/RawMaterialSupply.svg' style={{ height: "150px", width: "150px", objectFit: "cover" }} />
              </div>
              <div className="col-xs-12  col-sm-9" style={{ fontSize: "20px", color: "#000" }} >
                <h5 style={{ fontSize: "33.72px", color: "#04423C" }}>Consistent raw material supply</h5>
                A forecast showing the predicted production for the project is presented. The forecast is based on valid tracking records and credible agricultural studies covering all critical parameters.</div>
            </div>
            <hr></hr>
            <div className="row" style={{ marginBottom: "30px" }}>
              <div className="col-xs-12  col-sm-3">
                <img alt="" src='/images/home/RawMaterialQuality.svg' style={{ height: "150px", width: "150px", objectFit: "cover" }} />
              </div>
              <div className="col-xs-12  col-sm-9" style={{ fontSize: "20px", color: "#000" }} >
                <h5 style={{ fontSize: "33.72px", color: "#04423C" }}>Consistent raw material quality</h5>
                Agricultura Plus delivers the quality required by the client. The company would benefit from the partnership established with the RBML food lab to ensure that the produce delivered always meets the promised quality.
              </div>
            </div>

            {/* <div className="row" >
              <h3 className="agri-color">Our Tools</h3>
            </div>
            <div className="row" style={{ marginLeft: "0" }}>
              <div className="col-sm-12 col-lg-6">
                <div className="row" style={{ marginLeft: "0" }}>
                  <div className="col-12">
                    <h5>The traceability dashboard</h5>
                    the traceability dashboard enables our users and clients to track all activities within the assigned project. The dashboard includes informative content related to the activities handled by Agricultura Plus and its project manager. All important parameters are visible for our clients including forecasts of both production and deliverables.</div>
                </div>
                <div className="row" style={{ marginLeft: "0" }}>
                  <div className="col-12">
                    <h5>Costumer service</h5>
                    High responsiveness is the key for our professional costumer service. Alongside the traditional communication tools, our clients can contact the team through the chat box found inside the traceability dashboard. Our team is ready to respond to any inquiries and to answer any questions.</div>
                </div>
                <div className="row" style={{ marginLeft: "0" }}>
                  <div className="col-12">
                    <h5>Standardized operational procedure</h5>
                    Agricultura Plus has developed efficient SOPs to ensure that all procedures are standardized. Through this, Agricultura Plus guarantees the same outcome for the same way of management. The atomization of the work would help the team achieve ultimate efficiency.
                  </div>
                </div>
                <div className="row" style={{ marginLeft: "0" }}>
                  <div className="col-12">
                    <h5>Feasibility Studies & Cost Benefit Analysis</h5>
                    Feasibility studies are conducted to help the company and the client define their needs and the size of the project that meets the demand. In addition, the cost benefit analysis reflects the beneficial outcome of outsourcing agriculture and the effect of the company’s services on the cost of the items, which strengthens the company's competitive edge.
                  </div>
                </div>

              </div>
              <div className="col-sm-12 col-lg-6">
                <img alt="" src='/images/home/sec2img.jpeg' style={{ height: "100%", width: "100%", objectFit: "cover" }} />
              </div>
            </div> */}
          </div>
          <Footer />
        </div>

        <Modal size="lg" style={{ fontSize: "20px", color: "#000" }} isOpen={this.state.openReadMoreModal} className="lg"  >
          <ModalBody>
            <div >
              Agricultura Plus was founded by a group of aspiring Lebanese entrepreneurs who have the needed experience and knowledge to assist businesses achieve vertical integration and sustainability. The developed business model is innovative as it works to eliminate several pain points that a business might face when it comes to supply. This concept will help corporates establish independency and balance bargaining powers.
              <br></br>
              <br></br>
              Agricultura Plus is specialized in establishing and monitoring agricultural projects covering several types of crops. To achieve ultimate efficiency, the company has defined a multiple set of tools to keep clients involved throughout the experience. Before signing any agreement, Agricultura Plus presents a well-developed feasibility study with forecasts and a cost benefits analysis, through which clients can easily track the benefits of the vertical integration in terms of price and stability. Moreover, industrial and food-processing companies struggle to find the right supplier that can supply consistent quality, quantity, and price, which is another essential problem that Agricultura aims to tackle.
              <br></br>
              <br></br>
              The company has developed a dashboard enabling the users to track all activities on an assigned project. The traceability dashboard shows the pace of activities, a tacking record of production, and forecasts for the upcoming years. This dashboard enhances the clients' experiences, as they would be able to follow relevant parameters related to the supply of raw materials.
              <br></br>
              <br></br>
              Projects are monitored by certified agronomists and project managers in order to guarantee the best service throughout our journey. A project can be established and/or monitored by Agricultura Plus based on the guidelines and the demands of the client.
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={this.openReadMoreModal}>Close</Button>
          </ModalFooter>
        </Modal>
      </div>
    )
  }

}

export default Services;