import React from 'react';
import {
  Navbar,
  Nav,
  NavLink,
} from 'reactstrap';


class AdminNavigator extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false
    }
  }

  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  handleChangePath = (url) => {
    if (document.getElementById("companyProductsContainer"))
      document.getElementById("companyProductsContainer").scrollTo(0, 0)
    window.scrollTo(0, 0)
    this.props.history.push(url);
  }

  render() {
    return (
      <div id="AdminNavigator">
        <Navbar color="light" light expand="md">

          <Nav vertical navbar >

            <NavLink onClick={() => { this.handleChangePath("/SystemManagement/ProjectList") }} >
              Project List
            </NavLink>
            <NavLink onClick={() => { this.handleChangePath("/SystemManagement/NewProject") }} >
            New Project
            </NavLink>
            <NavLink onClick={() => { this.handleChangePath("/SystemManagement/NewClient") }} >
              New Client
            </NavLink>
            <NavLink onClick={() => { this.handleChangePath("/SystemManagement/ClientsList") }} >
              Clients List
            </NavLink>
            <NavLink onClick={() => { this.handleChangePath("/SystemManagement/NewLand") }} >
              New Land
            </NavLink>
            <NavLink onClick={() => { this.handleChangePath("/SystemManagement/LandsList") }} >
              Lands List
            </NavLink>

          </Nav>
        </Navbar>


      </div>
    );
  }
}

export default AdminNavigator;