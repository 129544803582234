import React from 'react';
import './App.css';
import MainNavigator from './appContainers/MainNavigator';
import { Router, Switch, Route } from "react-router-dom";
import { Spinner } from 'reactstrap';
import { createHashHistory } from 'history';  

import Home from "./appContainers/appMainPages/Home";
import Services from './appContainers/appMainPages/Services';
import Partnerships from './appContainers/appMainPages/Partnerships';
import Agreements from './appContainers/appMainPages/Agreements';
import Products from './appContainers/appMainPages/Products';
import Projects from './appContainers/appMainPages/Projects';
import OurStory from './appContainers/appMainPages/OurStory';
import About from './appContainers/appMainPages/About';
import Contact from './appContainers/appMainPages/Contact';
import Login from './appContainers/appMainPages/Login';
import SystemManagement from './appContainers/adminPages/SystemManagement';
import Dashborar from './appContainers/dashborad/Dashborad';
import ManagerForms from './appContainers/managerForms/ManagerForms';

const history = createHashHistory()

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loginUser: ''
    };
  }

  setIsAuthenticated = (loginUser) => {
    this.setState({ loginUser })
  }

  render() {
    let { loginUser } = this.state
    return (
      <div className="App">
        <div id="SpinnerMaincontainer">
          <Spinner id="Spinnercontainer" color="primary" />
        </div>
        <div id="AppHeaderContainer">
          <MainNavigator history={history} loginUser={loginUser}  setIsAuthenticated={this.setIsAuthenticated} />
        </div>
        <div id="AppBodyContainer" style={{ height: "calc(100% - 81px)" }} >
          <Router history={history}>
            <Switch>
              <Route path="/Home" component={Home} />
              <Route path="/Services" component={Services} />
              <Route path="/Partnerships" component={Partnerships} />
              <Route path="/Products" component={Products} />
              <Route path="/Agreements" component={Agreements} />
              <Route path="/Projects" component={Projects} />
              <Route path="/OurStory" component={OurStory} />
              <Route path="/About" component={About} />
              <Route path="/Contact" component={Contact} />
              <Route path="/Login" render={() => <Login history={history} setIsAuthenticated={this.setIsAuthenticated} />} />
              <Route path="/Dashborad" component={Dashborar} />
              <Route path="/ManagerForms" component={ManagerForms} />
              <Route path="/SystemManagement" component={SystemManagement} />

              <Route path="/" exact component={Home} />
              {/* <Redirect path="/" /> */}
            </Switch>
          </Router>
        </div>
        <div id="AppFooterContainer">
        </div>
      </div>
    )
  }
}
export default App;

