import React from 'react';
import Footer from './Footer';

class Partnerships extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {

    return (
      <div id="PartnershipsMainContainer" className="container" >

        <div className="MainImgAndTitleContainer">
          <div className="MainImgContainer" />
          <div className="TitleContainer">Partnerships</div>
        </div>

        <div id="PartnershipsContainer">
          <div id="PartnershipsTopContainer" className="agri-color"  >
            <h5>Agricultura Plus is establishing partnerships with several NGO's to make sure that the company is following operational sustainability and to help near communities grow.</h5>
          </div>
          <div id="PartnershipsBodyContainer">
            <div id="PartnershipsIcons" className="row">
              <div className="col-3 col-sm-3 col-md-1"/>
              <div className="col-4 col-sm-4 col-md-2" style={{padding:"0"}}>
              <img alt="" src='/images/partnership/BazcoGroup.png' style={{ height: "120px", width: "120px", objectFit: "contain" }} />
              </div>
              <div className="col-4 col-sm-4 col-md-2" style={{padding:"0"}}>
              <img alt="" src='/images/partnership/woodenbakery.png' style={{ height: "120px", width: "120px", objectFit: "contain" }} />
              </div>
              <div className="col-4 col-sm-4 col-md-2" style={{padding:"0"}}>
              <img alt="" src='/images/partnership/FakraTourass.png' style={{ height: "120px", width: "120px", objectFit: "contain" }} />
              </div>
              <div className="col-4 col-sm-4 col-md-2" style={{padding:"0"}}>
              <img alt="" src='/images/partnership/FFBlogo.png' style={{ height: "120px", width: "120px", objectFit: "contain" }} />
              </div>
              <div className="col-4 col-sm-4 col-md-2" style={{padding:"0"}}>
              <img alt="" src='/images/partnership/GROUPKA.jpeg' style={{ height: "120px", width: "120px", objectFit: "contain" }} />
              </div>
              <div className="col-1"/>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-5">
                <img alt="" src='/images/home/Partnership.jpg' style={{ height: "100%", width: "100%", objectFit: "cover" }} />
              </div>
              <div className="col-sm-12 col-md-7">
                <h5>It's time for thyme</h5>
                <div>Source: UNDP Lebanon</div>
                <div>
                  UNDP, in partnership with Germany through Kfw, is supporting 2,312 farmers across 113 villages in Lebanon in leading the path towards a sustainable and green economic recovery through cash-for-work to stimulate local markets, especially in rural areas, creating a ripple effect of benefits for vulnerable people across Lebanon. This comprehensive intervention has generated 250.000 worker days so far for more than 6,000 women and men working in the fields and in agro-food production.
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    )
  }

}

export default Partnerships;

