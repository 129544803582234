import React from 'react';

function footer(props) {

  return (

    <div id="footerMainContainer">
      <div id="footer" className="row">
        <div className="col-12" style={{ paddingTop: "30px", margin: "auto" }} >
          <h3 style={{ marginLeft: "208px", textAlign: "left", fontSize:"26.84px", fontWeight:"bolder", color:"#04423C" }}>Let's Get Social</h3>
        <div style={{overflow:"auto"}}>
          <div id="footerFollowUS">
            <div><img alt="" src='/images/footer/SM-01.png' height="125" /></div>
            <div><img alt="" src='/images/footer/SM-02.png' height="125" /></div>
            <div><img alt="" src='/images/footer/SM-05.png' height="125" /></div> 
            <div><img alt="" src='/images/footer/SM-04.png' height="125" /></div>
            <div><img alt="" src='/images/footer/SM-07.png' height="125" /></div>
            <div>
              <div style={{ height: "125px", width: "125px" }}>
                <div className='arrowMainContainerFooter'>
                  <div className='arrowContainerFooter'><a href='https://www.instagram.com/timetothyme.lb/' target="_blank" rel="noopener noreferrer">
                    <div className='arrowTextFooter' style={{color:"#22B05B"}}>FOLLOW US</div>
                    <div className='arrowLineFooter'></div>
                    <div className='arrowArrowFooter' >&#62;</div></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
          <div style={{ clear: "both" }}></div>
          <hr></hr>
        </div>
        <div id="footerSocialMedia" style={{ paddingTop: "30px" }}>
          {/* <div><img alt="" src='/images/footer/Component112.svg' height="22px" /></div> */}
          {/* <div style={{paddingLeft:"55px"}}><img alt="" src='/images/footer/Component113.svg' height="22px" /></div> */}
          {/* <div style={{paddingLeft:"55px"}}><img alt="" src='/images/footer/Component114.svg' height="22px" /></div> */}
          <div style={{paddingLeft:"50px"}}><a href='https://www.youtube.com/channel/UChOJuC8BqU1m7r82vsEDaow' target="_blank" rel="noopener noreferrer"><img alt="" src='/images/footer/Component115.svg' height="22px" /></a></div>
          <div style={{paddingLeft:"50px"}}><a href='https://www.linkedin.com/company/agricultura-plus' target="_blank" rel="noopener noreferrer"><img alt="" src='/images/footer/Component116.svg' height="22px" /></a></div>
        </div>
        <p className="col-12" style={{ color: "#151515",    textAlignLast: "center" }}> Copyright© 2021 Agricultura Plus. All rights reserved.</p>
      </div>
    </div>


  )
}

export default footer;