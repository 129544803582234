import React from 'react';
import Footer from './Footer';
import { map } from 'lodash'

class Products extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
    this.dataArray = [
      {
        image: '/images/thyme/1.png',
        title: "ORIGINAL MIX",
        quantity: ["200g.", "450g.", "900g."],
        ingredients: "ingredients: Thyme, Sumac, Seasme, Salt."
      },
      {
        image: '/images/thyme/2.png',
        title: "CRUNCHY MIX",
        quantity: ["200g.", "450g.", "900g."],
        ingredients: "ingredients: Thyme, Sumac, Seasme, Salt, Hazelnut, Cashew, Almond, Walnut, Peanut."
      },
      {
        image: '/images/thyme/3.png',
        title: "HERBY MIX",
        quantity: ["200g.", "450g.", "900g."],
        ingredients: "ingredients: Thyme, Sumac, Salt, Flax, Mint, Anise."
      },
      {
        image: '/images/thyme/4.png',
        title: "SPICY MIX",
        quantity: ["200g.", "450g.", "900g."],
        ingredients: "ingredients: Thyme, Sumac, Seasme, Salt, Chili Powder, Ginger."
      },
      {
        image: '/images/thyme/5.png',
        title: "GARLIC MIX",
        quantity: ["200g.", "450g.", "900g."],
        ingredients: "ingredients: Thyme, Sumac, Seasme, Salt, Peanut, Garlic Powder."
      },
      {
        image: '/images/thyme/6.png',
        title: "FRUITY MIX",
        quantity: ["200g.", "450g.", "900g."],
        ingredients: "ingredients: Thyme, Sumac, Seasme, Salt, fig, Apricot, Raisin."
      },
      {
        image: '/images/thyme/7.png',
        title: "PURE SUMAC",
        quantity: ["200g.", "450g.", "900g."],
        ingredients: "ingredients: Sumac."
      },
      {
        image: '/images/thyme/8.png',
        title: "ORiginal THYME",
        quantity: ["200g.", "450g.", "900g."],
        ingredients: "ingredients: Grinded Thyme."
      },
    ]
  }

  render() {
    return (
      <div id="ProductsMainContainer" className="container" >

        <div className="MainImgAndTitleContainer">
          <div className="MainImgContainer" />
          <div className="TitleContainer">Products</div>
        </div>

        <div id="ProductsContainer">

          <div id="ProductsTopContainer">

            <div className='row' style={{ marginBottom: "120px" }}>
              {
                map(this.dataArray, (eachElem, key) => {
                  return (
                    <div key={key} className='col-12 col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3'>
                      <div>
                        <img alt="" src={eachElem.image} style={{ height: "235px", width: "156px", objectFit: "contain" }} />
                      </div>
                      <div className='row'>
                        <div className="col-12" style={{ color: "#04423C", fontSize: "16px", textAlign: "left", fontWeight: "bold", padding: "0" }}>
                          {eachElem.title}
                        </div>
                        {map(eachElem.quantity, (eashQty, key) => {
                          return (
                            <div key={key} className="col-4" style={key === 0 ? { padding: "0" } : { padding: "0 3px" }}>
                              <p className="ProductsQuantity">{eashQty}</p>
                            </div>
                          )
                        })}
                        <div className="col-12" style={{ padding: "0", color: "#888888" }}>
                          <p className="ProductsIngredient">{eachElem.ingredients}</p>
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </div>
            <hr></hr>

            <div className='row'>
              <div className='col-12 col-xs-12 col-sm-12 col-md-4'>
                <img alt="" src='/images/thyme/3523.png' style={{ height: "33px", width: "33px", objectFit: "contain" }} />
                <p style={{ color: "#888888" }}>Regular $ Express Delivery Available</p>
              </div>
              <div className='col-12 col-xs-12 col-sm-12 col-md-4'>
                <img alt="" src='/images/thyme/3521.png' style={{ height: "33px", width: "33px", objectFit: "contain" }} />
                <p style={{ color: "#888888" }}>100% Satisfaction Guaranteed</p>
              </div>
              <div className='col-12 col-xs-12 col-sm-12 col-md-4'>
                <img alt="" src='/images/thyme/3522.png' style={{ height: "33px", width: "33px", objectFit: "contain" }} />
                <p style={{ color: "#888888" }}>Call us on +961 81 405 864</p>
              </div>
            </div>
              <hr></hr>

            <div className='row' style={{ marginBottom: "120px", marginTop: "120px"  }}>
              <div className='col-12 col-xs-12 col-sm-12 col-md-6'>
                <img alt="" src='/images/thyme/GEO_3452.png' style={{ marginBottom: "18px", width: "100%", objectFit: "contain" }} />
              </div>
              <div className='col-12 col-xs-12 col-sm-12 col-md-6'>
                <img alt="" src='/images/thyme/GEO_3448.png' style={{ marginBottom: "18px", width: "100%", objectFit: "contain" }} />
                <img alt="" src='/images/thyme/GEO_3449.png' style={{ width: "100%", objectFit: "contain" }} />
              </div>
            </div>
          </div>
          <div id="ProductsBodyContainer">
          </div>
          <Footer />
        </div>
      </div>

    )
  }

}

export default Products;